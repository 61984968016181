import React from 'react'
import Navitem from './Navİtem'
import NavitemHasChildren from './NavItemChildren'
import "./Sidebar.css"
const ToggleSidebar = ({isActive}) => {
    const easmPlatform = [
        {
            content: "Platform Overview",
            linkTo: "platformOverview"
        }
    ]

       
      
    const modules = [
         {
            content : "Domain Discovery",
            linkTo: "modules/subdomain-discovery",
         },
         {
            content : "Technology Discovery",
            linkTo:"modules/technology-discovery",
         },
         {
            content : "Service Discovery",
            linkTo: "modules/service-discovery",
         },
         {
            content : "Cloud Discovery",
            linkTo: "modules/cloud-discovery",
         },
         {
            content : "Darkweb Leak Discovery",
            linkTo: "modules/darkweb-leak-discovery",
         },
         {
            content : "Brand Discovery",
            linkTo: "modules/brand-discovery",
         },
         {
            content : "SSL Monitoring",
            linkTo: "modules/ssl-monitoring",
         },
         {
            content : "Issues Discovery",
            linkTo: "modules/issues-discovery",
         },
         {
            content :  "IP Discovery",
            linkTo:  "modules/ip-discovery",
         },
         {
            content :  "Threat Intel Feed",
            linkTo:   "modules/threat-intel-feed"
         },
    ]

    const resources = [
        {
            content: "Attack Surface Management",
            linkTo: "/blog/what-is-attack-surface-management"
        },
        {
            content: "Importance of EASM in Cloud Services",
            linkTo: "/blog/risk-of-cloud-services"
        },
        {
            content: "Danger of Exposed Ports",
            linkTo: "/blog/risk-of-ports"
        },
    ]
  return (
    <aside className={`toggle-sidebar-nav-wrapper ${isActive ? "active" :""}`}>
          <strong className='ms-5 mb-5 title text-white'>SURFACEMON</strong>
        <nav className="sidebar-nav mt-5 h-100">
          <ul className='d-flex justify-content-between flex-column h-100'>
            <div>
                <NavitemHasChildren  text="EASM Platform" list={easmPlatform} id="easm" />
                <NavitemHasChildren  text="Modules" list={modules} id="modules" />
                <Navitem text="Blog"   linkTo="blog" />
                <Navitem text="About Us"   linkTo="aboutUs" />
                <Navitem text="Contact Us"  linkTo="contactUs" />
                <NavitemHasChildren  text="Resources" list={resources} id="resources" />
                <Navitem text="Knowledge Base"  linkTo="knowledge-base" />
            </div>
          </ul>
        </nav>
      </aside>
  )
}

export default ToggleSidebar