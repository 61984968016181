import React from 'react'
import "./SecondSection.css"
import image1 from "../../assets/img/img-1.png"
import image2 from "../../assets/img/img-2.png"
import image3 from "../../assets/img/img-3.png"
import Row from './Row'
const SecondSection = () => {

const data = [
    {
        title: "Discover Your External Attack Surface",
        paragraph : "Scan and monitor an organization's external network and internet-facing assets for vulnerabilities and potential security threats.",
        imgSrc : image1
    },
    {
        title: "Easy to Use",
        paragraph : "Our panel is designed to be easy and natural to use, allowing users to quickly and easily accomplish their tasks without the need for extensive training or instructions.",
        imgSrc : image2
    },
    {
        title: "Customizable Alerts",
        paragraph : "Customizable alerts and risk-scoring algorithms to help organizations prioritize and address potential vulnerabilities based on their unique needs and threat landscape.",
        imgSrc : image3
    },
]

  return (
    <div className='second-section container-fluid mb-5 '>
        <Row 
            title={data[0].title} 
            paragraph={data[0].paragraph} 
            imgSrc={data[0].imgSrc}    
        />
        <Row 
            title={data[1].title} 
            paragraph={data[1].paragraph} 
            imgSrc={data[1].imgSrc} 
            className="flex-row-reverse"    
        />
        <Row 
            title={data[2].title} 
            paragraph={data[2].paragraph} 
            imgSrc={data[2].imgSrc}   
        />
    </div>
  )
}

export default SecondSection