import React from 'react'
import Tags from '../../../Blog/Tags'
import './Card.css'
import CardHeader from './CardHeader'
import CardNav from './CardNav'

const Card = ({description , header, tags, link, className, date, handleTagClick}) => {
  return (
    <div className={`${className} mb-4 position-relative`}>
      <div className='d-flex row mt-3'>
        {
          tags && tags.map((tag,index) => <Tags key={index} isShow={false} handleTagClick={handleTagClick}  text={tag} /> )
        }
      </div>
      
        <CardHeader header={header}  />
        <p className='card-description text-start  '>
        {description}
        
        </p>
        {date && <p className='text-end position-absolute text-secondary fw-bold bottom-0 end-0 me-2'> {date} </p>}
        <CardNav  linkTo={link} /> 
    </div>
  )
}

export default Card