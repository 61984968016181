import React from 'react'
import InfoBlock from './InfoBlock'
import './Footer.css'
import FooterInfoText from './FooterInfoText'
import FooterHeaders from './FooterHeaders'
import FooterIcon from './FooterIcon'

const Footer = () => {
  return (
    <div className='container-fluid   footer-container mt-5'>
        <div className='row d-flex justify-content-evenly py-5'>
            <div className='col-xxl-1 col-lg-2 col-sm-2'>
                <FooterHeaders text='SURFACEMON' />
                <div className='d-flex mt-3'>
                    <FooterIcon iconName='bi-linkedin'  />
                    <FooterIcon iconName='bi-twitter'  />
                    <FooterIcon iconName='bi-youtube'  />
                </div>
            </div>
            <div className='col-xxl-2 col-lg-3 col-sm-3 '>
                <InfoBlock text='Platform' >
                    <FooterInfoText  text='Platform Overview' link="platformOverview" />
                </InfoBlock>
            </div>
            
            <div className='col-xxl-2 col-lg-3 col-sm-3 '>
                <InfoBlock text='Modules ' >
                    <FooterInfoText  text='Domain Discovery'  link="modules/subdomain-discovery"/>
                    <FooterInfoText  text='Technology Discovery' link="modules/technology-discovery"/>
                    <FooterInfoText  text='Service Discovery' link="modules/service-discovery" />
                    <FooterInfoText  text='Cloud Discovery' link="modules/cloud-discovery" />
                    <FooterInfoText  text='Darkweb Leak Discovery' link="modules/darkweb-leak-discovery" />
                    <FooterInfoText  text='Brand Discovery' link="modules/brand-discovery" />
                    <FooterInfoText  text='SSL Monitoring' link="modules/ssl-monitoring" />
                </InfoBlock>
            </div>
            <div className='col-xxl-2 col-lg-3 col-sm-3 '>
                <InfoBlock text='Resources ' >
                    <FooterInfoText  text='Attack Surface Management'  link="blog/what-is-attack-surface-management"/>
                    <FooterInfoText  text='Importance of EASM in Cloud Services' link="blog/risk-of-cloud-services"/>
                    <FooterInfoText  text='Danger of Exposed Ports' link="blog/risk-of-ports" />
                </InfoBlock>
            </div>
            <div className='col-xxl-2 col-lg-3 col-sm-3 '>
            <InfoBlock text='Contact ' >
            <p  style={{color: "rgb(175, 180, 184)"}} ><strong>Company Address:</strong> <span> Maslak Mah. AOS 55 sk.42 Maslak B BLOK No:4/542 SARIYER, İSTANBUL/TURKEY </span> </p> 
                <p  style={{color: "rgb(175, 180, 184)"}} ><strong>Company Phone:</strong><span> +90 212 924 24 16 </span> </p> 
                    </InfoBlock>
            </div>
        </div>
    </div>
  )
}

export default Footer