import React from 'react'
import { NavLink } from 'react-router-dom'

const Navitem = ({text, iconName, linkTo, padding, className, style}) => {
  return (
    <li className="nav-item text-start"> 
        
        <NavLink  to={`/${linkTo}`} className={({ isActive }) => isActive ? `active-class ${className}` : className} style={style} >

          <span className={`text ${padding}`}>{text}</span>
        </NavLink>
    </li>
  )
}

export default Navitem