import React from 'react'
import Card from './Card'
import AnimatedText from '../AnimatedText/AnimatedText';
import cloud from "../../assets/svg/cloud.svg"
import safety from "../../assets/svg/Safety_Line.svg"
import team from "../../assets/svg/team.svg"
import './ThirdSection.css'

const ThirdSection = () => {
  const data = [
    {
      icon: cloud,
      header: 'Centered Management',
      text: 'Detect, manage, fix and follow-up from a single web app.'
    },
    {
      icon: safety,
      header: 'Proactive Defense',
      text: 'Discover threats before attackers exploit it. An attacker like approach to your benefit.'
    },
    {
      icon: team,
      header: 'Work Better Together     ',
      text: 'Align security, IT & dev teams, increase efficiency and boost collaborations both within and outside of your organization.'
    },
  ]
  return (
    <div className='container third-section'>
      <div className='row d-flex justify-content-center'>
      <AnimatedText />
        {
          data.map(dataItem => (
              <Card text={dataItem.text} icon={dataItem.icon} header={dataItem.header} />
          ))
        }
      </div>
    </div>
  )
}

export default ThirdSection