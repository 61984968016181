import thirdPartyFilters from "../src/assets/img/third-party-filters.png"
import thirdPartyModal from "../src/assets/img/third-party-modal.png"
import thirdPartyPage from "../src/assets/img/third-party-page.png"

function ThirdPartyLeaks() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Third Party Leaks Page</h2>
            <p>Third-Party Leaks page contains your inventory of leaked corporate emails by third-party applications with some additional information such as Leaked Database, Email and Password information. Please see additional items section for more definitions of Third-Party Leaks.</p>
            <img className="w-md-75" src={thirdPartyPage} alt="Third Party Page"  />
            <p className="mt-5 ">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={thirdPartyFilters} alt="Third Party Filter And Buttons"  />
           <p className="my-5">Additionally, you can get more information about the issue, remediations, put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={thirdPartyModal} alt="Third Party Details Modal"  />
        </div>
    )
}

export default ThirdPartyLeaks
