import React from 'react'
import Button from './Header/Button/Button'

const GetADemo = () => {
  return (
    <div className='d-flex flex-column get-demo position-relative align-items-center justify-content-center py-5'>
        <h1 className='mb-5' style={{ color: "#2D4263" }}>READY TO START?</h1>
        <Button text="Get a Demo"  style="#fff" linkTo="contactUs" className="px-5" />
    </div>
  )
}

export default GetADemo