import info from "../src/assets/img/intro.png"

function Introduction() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <p>Surfacemon is a SaaS tool provides enterprises with the capability to exert rigorous control over their External Attack Surface by implementing advanced, automated continuous monitoring systems.</p>
            <h5>Our enterprise tool covers following topics:</h5>
            <ul>
                <li className="mb-1">	Domain assets discovery read more,</li>
                <li className="mb-1">	TCP services discovery read more</li>
                <li className="mb-1">	Web technologies discovery read more</li>
                <li className="mb-1">	AWS, GCP and Azure external entities discovery (Websites, Buckets) read more </li>
                <li className="mb-1">	AWS, GCP and Azure external entities discovery (Websites, Buckets) read more </li>
                <li className="mb-1">	Third-party password leaks discovery read more </li>
                <li className="mb-1">	Dark-web password leaks discovery read more  </li>
                <li className="mb-1">	SSL-based issues discovery read more   </li>
                <li className="mb-1">	Threat Intelligence (IOC)</li>
                <li className="mb-1">	CVE-based issues discovery</li>
                <li className="mb-1">	Misconfiguration-based issues discovery</li>
                <li>	IP assets discovery</li>

                <p className="my-4"> Here is a succinct overview of the operational mechanics:</p>
                <img className="w-25" src={info} alt="Overview of the operational mechanics" />
            </ul>
        </div>
    )
}

export default Introduction
