import Footer from "../MainPage/Footer/Footer"
import Header from "../MainPage/Header/Header"
import ToggleSidebar from "../Sidebar/ToggleSidebar"

function IssuesDiscovery({handleClick, isActive}) {
    return (
        <div>  
       <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <div class="container-fluid h-100 d-flex justify-content-center ">
           
            <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                <h2 className='mb-5' style={{color: "#0C007F"}}>Issues Discovery </h2>
                <p className='mb-2 paragraph'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In today's cybersecurity battlefield, staying one step ahead of threats is non-negotiable. That's where Surfacemon EASM (External Attack Surface Management) enters the fray, bringing a formidable weapon against the dual threats of Common Vulnerabilities and Exposures (CVE) and misconfigurations within your digital ecosystem.

                </p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Unmasking Vulnerabilities with Precision: Surfacemon EASM  </span> <br/> <br/>Surfacemon EASM stands at the forefront of vulnerability discovery, providing an unparalleled defense mechanism that is meticulously designed to identify and mitigate both CVEs and misconfiguration-based vulnerabilities. CVEs, as publicly known security flaws, pose a significant risk to the integrity of an organization's infrastructure, demanding immediate and decisive action. Surfacemon EASM serves as a guardian, offering organizations a comprehensive and continuously updated database of CVEs, enabling swift identification and response to these documented threats.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Navigating the Undercurrents of Misconfigurations </span><br/> <br/>The silent menace of misconfigurations often escapes notice until it’s too late. Surfacemon EASM cuts through this oversight by meticulously scanning for and pinpointing configuration lapses that could leave your systems vulnerable to exploitation. This proactive stance ensures that these hidden faults are brought to light and addressed before they can be exploited by adversaries.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>The Surfacemon EASM Advantage</span> <br/> <br/> What sets Surfacemon EASM apart is its seamless integration into your security protocol. With minimal information required and zero installation hassle, this tool is primed to fortify your cybersecurity posture from day one. It operates as a vigilant watchtower, utilizing sophisticated scanning technologies to constantly monitor your attack surface for vulnerabilities. </p>
               
                <p className='mb-2 paragraph'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;With Surfacemon EASM, you gain:
                </p>
                
                <p className='mb-2 paragraph'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Continuous, automated scanning for real-time threat detection<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Streamlined identification of both CVEs and misconfiguration vulnerabilities<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- Prioritized insights enabling targeted and immediate remediation actions<br/>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;- User-friendly interface to navigate and manage security concerns with ease
                </p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Your Proactive Cybersecurity Partner </span> <br/> <br/>In the dynamic landscape of cyber threats, Surfacemon EASM distinguishes itself by not just responding to incidents, but by preventing them. It empowers organizations to validate, prioritize, and remediate potential vulnerabilities effectively. Remediation efforts are straightforward with actionable intelligence delivered directly by the tool, ensuring that your defenses are always robust and up to date.</p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Embrace the Future of Cybersecurity with Surfacemon EASM </span> <br/> <br/>As the digital domain continues to evolve, Surfacemon EASM is the ally you need in your cybersecurity arsenal. It's more than a tool; it's a partnership for a future where your organization can thrive without the looming shadow of cyber threats. Embrace Surfacemon EASM — where advanced technology meets unwavering security vigilance.</p>
                <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Discover how Surfacemon EASM can revolutionize your approach to cybersecurity. Secure your peace of mind today with Surfacemon EASM  your proactive defense against CVE and misconfiguration vulnerabilities.
                </p>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default IssuesDiscovery





