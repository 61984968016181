import React from 'react'
import { NavLink } from 'react-router-dom'

const CardNav = ({linkTo}) => {
  return (
    <NavLink className='d-flex text-dark fw-bold position-absolute bottom-0 card-nav' to={`/${linkTo}`}>
        <p>Read More</p>
        <i className="bi bi-arrow-right ms-2 fw-bold card-nav-icon"></i>
    </NavLink>
  )
}

export default CardNav