import React from 'react'

const Number = ({num}) => {
  return (
    <div className='number-wrapper justify-content-center d-flex align-items-center'>
        {num}
    </div>
  )
}

export default Number