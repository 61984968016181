import issuesTechFilters from "../src/assets/img/issues-tech-filters.png"
import issuesTechModal from "../src/assets/img/issues-tech-modal.png"
import issuesTechPage from "../src/assets/img/issues-tech-page.png"
import issuesTechInvite from "../src/assets/img/issues-poc-invite.png"

function IssuesByTech() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Issues By Tech</h2>
            <p>Issues by Tech page contains your inventory of patch-management issues with additional information such as CVE, Severity, Status and Assignee.</p>
            <img className="w-md-75" src={issuesTechPage} alt="Issues by Tech Page"  />
            <p className="mt-5 ">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={issuesTechFilters} alt="Issues by Tech Filter And Buttons"  />
            <p className="mt-5">You can assign issues to your team-mates by using the +   icon under the Assignee column. </p>
            <img  className="w-md-25" src={issuesTechInvite} alt="Issues by Tech Assignee"  />
            <p className="my-5">Additionally, you can get more information about the issue, remediations, put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50"  src={issuesTechModal} alt="Issues by Tech Details Modal"  />
        </div>
    )
}

export default IssuesByTech
