import React from 'react'
import { Link } from 'react-router-dom'

const DropdownLink = ({text,url}) => {
  return (
    <li className='w-100 d-flex align-items-center'>
        <a href={`/${url}`}  className="dropdown-item" >
          {text}
        </a>
    </li>
  )
}

export default DropdownLink