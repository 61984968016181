import Footer from "../MainPage/Footer/Footer"
import Header from "../MainPage/Header/Header"
import ToggleSidebar from "../Sidebar/ToggleSidebar"

function IpDiscovery({handleClick, isActive}) {
    return (
        <div>  
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <div class="container-fluid h-100 d-flex justify-content-center ">
           
            <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                <h2 className='mb-5' style={{color: "#0C007F"}}>IP Discovery </h2>
                <p className='mb-2 paragraph'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In an age where digital assets form the backbone of corporate operations, visibility into every facet of your company's online presence is vital for security and stability. Surfacemon is your dedicated partner in casting a revealing light on all the public IP addresses owned by your company, ensuring that you retain command over your digital territory.

                </p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Discover the Unseen with Surfacemon</span> <br/> <br/>Hidden corners of the internet can harbor risks, and every public IP address linked to your company is a potential doorway to threats. Surfacemon offers a laser-focused solution that scours the vastness of the internet to pinpoint and catalog every public IP address your enterprise holds. This powerful reconnaissance is essential in constructing a comprehensive security posture.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Why Surfacemon for Public IP Address Detection?</span><br/> <br/>Surfacemon is engineered to provide an exhaustive inventory of your company’s public IP assets. Here’s what makes Surfacemon an indispensable tool:</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Comprehensive Visibility:</span>Surfacemon sweeps through the internet, bringing every public IP associated with your company into sharp focus.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Continuous Monitoring</span> The digital landscape is ever-changing; new IP addresses can be added to your network. Surfacemon diligently tracks these changes, offering real-time updates.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Risk Management: </span>By knowing exactly what you own, you can better defend it. Surfacemon aids in identifying vulnerable points within your public IP space, enabling preemptive security measures.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Simplified Compliance: </span> Many regulatory frameworks require knowledge and control over your digital assets. Surfacemon simplifies compliance with its detailed reporting capabilities.</p>
                <p className='mb-5 paragraph'> <span className='text-key'>Operational Efficiency: </span>Streamline your network management with the clarity that Surfacemon provides, optimizing the allocation and usage of your IP resources.</p>
               
                <h4 className='mb-2'>Surfacemon: Your Strategic Advantage</h4>
                
                <p className='mb-5 paragraph'>
                Harness the power of complete awareness with Surfacemon, and turn what was once invisible into a clear map of your digital assets. This insight is not just a matter of knowledge—it's a strategic advantage in a realm where the unseen can define your security narrative.<br/>
                With Surfacemon, you embark on a journey of discovery that transcends traditional boundaries, ensuring that every byte of your public IP space is accounted for, monitored, and protected.<br/>
    
                </p>
                <h4 className='mb-3'>Your Journey to Comprehensive Digital Awareness Begins</h4>

                <p className='mb-2 paragraph'>Take the first step towards full digital transparency and fortified security with Surfacemon.
                </p>
                <p className='mb-2 paragraph'>Don’t let the unknowns dictate your security posture. Claim the power of complete visibility with Surfacemon, and watch over your digital domain with confidence and control. Secure your network, protect your assets, and propel your business forward with the insight that only Surfacemon can provide.
                </p>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default IpDiscovery
