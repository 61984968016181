import React from 'react'
import { NavLink } from 'react-router-dom'
import './Button.css'

const Button = ({text, linkTo, className , style}) => {
  return (
    <button className={`custom-button d-flex align-items-center fw-bold ${className}`}>
       <NavLink exact  to={`/${linkTo}`}  style={{color: style}}  >
          {text}
       </NavLink>
      
    </button>
  )
}

export default Button