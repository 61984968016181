import servicesFilters from "../src/assets/img/services-filters.png"
import servicesModal from "../src/assets/img/services-modal.png"
import servicesPage from "../src/assets/img/services-page.png"

function Services() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Services Page</h2>
            <p>Services page contains your service inventory with additional information such as IP, Service and Service labels.</p>
            <img className="w-md-75 my-4" src={servicesPage} alt="Sidebar"  />
            <p>You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={servicesFilters} alt="Services Filter And Buttons"  />
            <p>Additionally, you can put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={servicesModal} alt="Services Details Modal"  />
        </div>
    )
}

export default Services
