import React ,{ useEffect,  useState } from 'react'
import HeaderDropdown from './HeaderDropdown/HeaderDropdown'
import './Header.css'
import ToggleButton from '../../ToggleButton/ToggleButton'
import { NavLink } from 'react-router-dom'
import logo from "../../assets/img/surfacemon-logo.png"
const Header = ({handleClick, page}) => {
  const linkData = [
    "Platform Overview"
  ]

  const platrformUrls = [
    "platformOverview"
  ]
  const blogData = [
    "Attack Surface Management",
    "Importance of EASM in Cloud Services",
    "Danger of Exposed Ports"
  ]
  const blogsUrl = [
    "blog/what-is-attack-surface-management",
    "blog/risk-of-cloud-services",
    "blog/risk-of-ports"
  ]
  const modulesData = [
    "Domain Discovery",
    "Technology Discovery",
    "Service Discovery",
    "Cloud Discovery",
    "Darkweb Leak Discovery",
    "Brand Discovery",
    "SSL Monitoring",
    "Issues Discovery",
    "IP Discovery",
    "Threat Intel Feed"
  ]
  const modulesUrl = [
    "modules/subdomain-discovery",
    "modules/technology-discovery",
    "modules/service-discovery",
    "modules/cloud-discovery",
    "modules/darkweb-leak-discovery",
    "modules/brand-discovery",
    "modules/ssl-monitoring",
    "modules/issues-discovery",
    "modules/ip-discovery",
    "modules/threat-intel-feed"
  ]
  const [stickyClass, setStickyClass] = useState('');

  useEffect(() => {
    window.addEventListener('scroll', stickNavbar);
    return () => window.removeEventListener('scroll', stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the demo
      windowHeight >  70 ? setStickyClass('sticky-nav') : setStickyClass('');
    }
  };
  return (
    <nav className={`d-flex w-100 header-nav mb-3 container-fluid justify-content-around align-items-center ${stickyClass}`}>
      <div className='text-start h-100'>
        <img  src={logo} alt='Surfacemon' className=' object-fit-scale h-100' />

{/*         <p className='mb-0 ps-5' style={{fontSize: "13px", marginLeft: "5rem"}}>Surfacemon is a part of <a href='https://centerondigital.com/'>centerondigital.com</a>  </p>
 */}     </div>
      <div className=' header-content align-items-center ms-auto me-md-5 me-sm-0'>
        <a href="/" className='d-flex align-items-center me-md-3 me-sm-2 me-xs-2 ' style={{color: "#0C007F", fontWeight: "500"}}>
            Home
        </a>
        <HeaderDropdown btnText='EASM Platform  '  linkData={linkData}  url={platrformUrls} />
        <HeaderDropdown btnText='Modules'  linkData={modulesData } url={modulesUrl} />
{/*         <HeaderDropdown btnText='Use Cases'  linkData={linkData} />
        <HeaderDropdown btnText='Reaesarch'  linkData={linkData} />
        <HeaderDropdown btnText='Partners'  linkData={linkData} /> */}
        {/* <HeaderDropdown btnText='Company'  linkData={linkData} /> */}
        <a href="/blog" className='d-flex align-items-center me-md-3 me-sm-2 me-xs-2 ' style={{color: "#0C007F", fontWeight: "500"}}>
            Blog
        </a>
        <a href="/aboutUs" className='d-flex align-items-center me-md-3 me-sm-2 me-xs-2 ' style={{color: "#0C007F", fontWeight: "500"}}>
            About Us
        </a>
        <a href="/contactUs" className='d-flex align-items-center ms-2' style={{color: "#0C007F", fontWeight: "500"}} >Contact Us</a>
            
        <HeaderDropdown btnText='Resources'  linkData={blogData } url={blogsUrl} />
        <a href="/knowledge-base" className='d-flex align-items-center ms-2' style={{color: "#0C007F", fontWeight: "500"}} >Knowledge Base</a>

      </div>
     { page !== "Knowledge Base" && <ToggleButton handleClick={handleClick} />}
     { page === "Knowledge Base" && <NavLink to="/" className="ms-auto text-black me-3 menu-toggle-btn" >Home</NavLink>}
      {/* 
      <Button text='Customer Login' linkTo='app.surfacemon.io' className={"mx-5"} style="#fff" /> */}
      <button className={`custom-button d-flex align-items-center fw-bold mx-md-5 ms-sm-0 me-sm-4 px-sm-2`}>< a href='http://app.surfacemon.io' style={{color: "#fff"}} >Customer Login</a></button>
    </nav>
  )
}

export default Header