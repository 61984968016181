import React from 'react'
import DropdownLink from './DropdownLink'

const HeaderDropdown = ({btnText, linkData, url}) => {
  return (
    <div className="dropdown header-dropdown mt-0 mx-md-2 mx-sm-0">
        <button className="btn dropdown-toggle d-flex align-items-center blue-text" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            {btnText}
            <i className="fa fa-angle-down ms-2" aria-hidden="true"></i>
        </button>
        <ul className="dropdown-menu py-0">
            {
              linkData.map((data, index) => <DropdownLink key={data} text={data}  url={url[index]} />)
            }
        </ul>
    </div>
  )
}

export default HeaderDropdown