import React from 'react'

const Tags = ({text, handleTagClick, isShow, handleTagClose}) => {
  return (
    <div   className='price-tag col-2 mt-2 d-flex'>
       <p onClick={() => handleTagClick(text)}>#{text}</p> 
        {isShow &&<button  className='border-0 text-white bg-transparent' onClick={handleTagClose}><i  className="bi bi-x"></i></button> }
    </div>
  )
}

export default Tags