import React from 'react'
import "./HowItWorks.css"
import HowItWorksItem from './HowItWorksItem'
import Number from './Number'

const HowItWorks = () => {
  return (
    <div   style={{backgroundColor: "#2D4263" , color: "#fff" }}>
        <h2 className='pt-5'>HERE IS HOW IT WORKS</h2>
        <h3 className='mt-3'>Surfacemon maps the your’s internet exposures in real-time</h3>
        <div className='container'>
          <div className='row my-5'>
            <HowItWorksItem  text="Map the entire internet for your exposed assets" num="1" />
            <HowItWorksItem  text="Create exposed asset inventory & analyze security posture" num="2"/>
            <HowItWorksItem  text="Prioritize Risks & generate a smart remediation plan" num="3" />
          </div>
        </div>
    </div>
  )
}

export default HowItWorks