import React from 'react'
import "./ToggleButton.css"

const ToggleButton = ({handleClick}) => {
  return (
    <div className="menu-toggle-btn mt-2 ms-auto me-3"  >
        <button id="menu-toggle" className="main-btn btn-hover" onClick={handleClick}>
            <i className="bi bi-list"></i>
        </button>
    </div>
  )
}

export default ToggleButton