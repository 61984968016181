import React from 'react'
import Button from '../Header/Button/Button'
import surfacemon from  '../../assets/img/surfacemon-backgroundless.png'
import './FirstSection.css'
const FirstSection = () => {
  return (
    <div className='container first-section mb-4'>
        <div className='row mb-5 w-100  d-flex justify-content-between'>
            <div className='col-lg-6  mt-5'>
                <h2 className='mb-5 title-style'>External Attack Surface Management</h2>
                <h1 className='mb-5 title-style'>Know What You Have, Secure What You Value. </h1>
                <p className='mt-3 mb-4 title-style first-section_desc'>Discover your internet facing assets, detect leaked passwords and locate well-known vulnerabilities before malicious actors do.</p>
                <div className='d-flex'>
                  <Button className="me-3"  text='See Your Attack Surface' style="#fff"  linkTo="contactUs" />
                  <Button  text='Platform Overview' style="#fff"  linkTo="platformOverview" />
                </div>
            </div>
            <div className='col-lg-5 d-flex align-items-center justify-content-end ms-3 world-animation-wrapper'>
                <img className='world-gif ' src={surfacemon}/>
            </div>
        </div>

    </div>
  )
}

export default FirstSection