import MainPage from "./MainPage/MainPage"
import './App.css';
import { Route, } from "react-router-dom";
import PlatformOverview from "./Platform/PlatformOverview";
import ContactUs from "./ContactUs/ContactUs";
import AboutUs from "./AboutUs/AboutUs";
import AttackSurface from "./Resources/AttackSurface";
import RiskCloudServices from "./Resources/RiskCloudServices";
import RiskofPorts from "./Resources/RiskofPorts";
import { useState } from "react";
import Blog from "./Blog/Blog";
import DifferencePenEasm from "./Blog/DifferencePenEasm";
import WhyWeShouldNotExposeDatabasestotheInternet from "./Blog/WhyWeShouldNotExposeDatabasestotheInternet";
import DangerOfShadowIT from "./Blog/DangerOfShadowIT";
import ThirdPartyRisks from "./Blog/ThirdPartyRisks";
import EthicalHackerPerspective from "./Blog/EthicalHackerPerspective";
import SubdomainDiscovery from "./Modules/SubdomainDiscovery";
import TechDiscovery from "./Modules/TechDiscovery";
import ServiceDiscovery from "./Modules/ServiceDiscovery";
import CloudDiscovery from "./Modules/CloudDiscovery";
import DarkWebDiscovery from "./Modules/DarkwebDiscovery";
import BrandDiscovery from "./Modules/BrandDiscovery";
import SSLMonitoring from "./Modules/SSLMonitoring";
import IssuesDiscovery from "./Modules/IssuesDiscovery";
import IpDiscovery from "./Modules/IpDiscovery";
import ThreatIntelFeed from "./Modules/ThreatIntelFeed";
import KnowledgeBase from "./KnowledgeBase";
import { Routes } from "react-router-dom/dist";
import PrioritizeVuln from "./Blog/PrioritizeVuln";
import Introduction from "./Introduction";
import OnboardingRequirements from "./OnboardingRequirements";
import InitialContact from "./InitialContact";
import LogIn from "./LogIn";
import PasswordReset from "./PasswordReset";
import Dashboard from "./Dashboard";
import DomainAssets from "./DomainAssets";
import WebTechnologies from "./WebTechnologies";
import Services from "./Services";
import CloudAssets from "./CloudAssets";
import IpAssets from "./IpAssets";
import Certificates from "./Certificates";
import IssuesByPoc from "./IssuesByPoc";
import IssuesByTech from "./IssuesByTech";
import ThirdPartyLeaks from "./ThirdPartyLeaks";
import DarkWebLeaks from "./DarkWebLeaks";
import IOCIntelligence from "./IOCIntelligence";
import CVENews from "./CVENews";
import Similar from "./Similar";
import CompanyProfile from "./CompanyProfile";
import ProfilePage from "./ProfilePage";
import GeneratingReports from "./GeneratingReports";
import UrgentScan from "./UrgentScan";
import AdditionalItems from "./AdditionalItems";

function App() {

    
  const [isActive, setIsActive] = useState(false);


  
 

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    
    setIsActive(current => !current);

   
  };


  return (
    <div className="App">
    
          <Routes>
            <Route index path="/" element={ <MainPage isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/platformOverview"  element={<PlatformOverview  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/contactUs"  element={<ContactUs   isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/aboutUs"  element={ <AboutUs   isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/what-is-attack-surface-management"  element={<AttackSurface  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/risk-of-cloud-services"  element={<RiskCloudServices  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/third-party-risks"  element={<ThirdPartyRisks  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/ethical-hacker-perspective"  element={<EthicalHackerPerspective  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/prioritize-vulnerabilities"  element={<PrioritizeVuln  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/risk-of-ports"  element={<RiskofPorts   isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/danger-of-shadow-IT" element={<DangerOfShadowIT  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/difference-between-pen-and-easm" element={<DifferencePenEasm  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog/expose-databases-to-internet" element={ <WhyWeShouldNotExposeDatabasestotheInternet  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/blog" element={ <Blog  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/subdomain-discovery" element={ <SubdomainDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/technology-discovery" element={ <TechDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/service-discovery" element={<ServiceDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/cloud-discovery" element={<CloudDiscovery />} />
            <Route  path="/modules/darkweb-leak-discovery" element={<DarkWebDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/brand-discovery" element={<BrandDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/ssl-monitoring" element={<SSLMonitoring isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/issues-discovery" element={<IssuesDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/ip-discovery" element={<IpDiscovery  isActive={isActive} handleClick={handleClick}   />} />
            <Route  path="/modules/threat-intel-feed" element={<ThreatIntelFeed  isActive={isActive} handleClick={handleClick}   />} />
            <Route element={ <KnowledgeBase />} >
                <Route path="/knowledge-base" element={<Introduction />}  />
                <Route path="/knowledge-base/onboarding-requirements" element={<OnboardingRequirements />}  />
                <Route path="/knowledge-base/initial-contact" element={<InitialContact />}  />
                <Route path="/knowledge-base/log-in" element={<LogIn />}  />
                <Route path="/knowledge-base/password-reset" element={<PasswordReset />}  />
                <Route path="/knowledge-base/dashboard" element={<Dashboard />}  />
                <Route path="/knowledge-base/domain-assets-page" element={<DomainAssets />}  />
                <Route path="/knowledge-base/web-techs-page" element={<WebTechnologies />}  />
                <Route path="/knowledge-base/services-page" element={<Services />}  />
                <Route path="/knowledge-base/cloud-assets-page" element={<CloudAssets />}  />
                <Route path="/knowledge-base/ip-assets-page" element={<IpAssets />}  />
                <Route path="/knowledge-base/certificates-page" element={<Certificates />}  />
                <Route path="/knowledge-base/issues-by-PoC" element={<IssuesByPoc />}  />
                <Route path="/knowledge-base/issues-by-tech" element={<IssuesByTech />}  />
                <Route path="/knowledge-base/third-party-leaks" element={<ThirdPartyLeaks />}  />
                <Route path="/knowledge-base/dark-web-leaks" element={<DarkWebLeaks />}  />
                <Route path="/knowledge-base/ioc-intelligence-page" element={<IOCIntelligence />}  />
                <Route path="/knowledge-base/cve-news-page" element={<CVENews />}  />
                <Route path="/knowledge-base/similar-domains" element={<Similar />}  />
                <Route path="/knowledge-base/company-profile-page" element={<CompanyProfile />}  />
                <Route path="/knowledge-base/profile-page" element={<ProfilePage />}  />
                <Route path="/knowledge-base/generating-reports" element={<GeneratingReports />}  />
                <Route path="/knowledge-base/urgent-scan" element={<UrgentScan/>}  />
                <Route path="/knowledge-base/additional-items" element={<AdditionalItems/>}  />
            </Route>

    
              
              {/* <Route exact path="/blog/:blogPosts">
                  <BlogPosts />
              </Route> */}
          </Routes>
    </div>
  );
}

export default App;
