import React from 'react'
import "./FourthSection.css"
import image from "../../assets/img/image-4.webp"
import Button from '../../MainPage/Header/Button/Button'

const FourthSection = () => {
  return (
    <div className='fourth-section text-white text-start'>
        <div className='row d-flex justify-content-center align-items-center'>
            <div className='col-lg-5 py-5 d-flex flex-column justify-content-center align-items-center'>
                <h2 className='mb-3 '>Ready to Discover Your Exposures?</h2>
                <h4 className=''>See What Your External Attack Surface Looks Like</h4>
                <Button text='Get Personalized Demo' className="bg-white text-dark  mt-4 text-start" style="#0C007F"  linkTo="contactUs" />
            </div>
        </div>
    </div>
  )
}

export default FourthSection