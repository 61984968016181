import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const BrandDiscovery = ({handleClick ,isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Brand Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In today's digital age, businesses and organizations must be vigilant when it comes to cyber security. One of the most common ways that cyber criminals can gain access to sensitive information is by creating fake websites and social accounts that mimic legitimate ones. To help organizations protect themselves against this type of threat, we are offering a module that discovers similar domains and social accounts.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our tool works by analyzing domain names and social account usernames to identify potential similarities. This includes variations in spelling, use of hyphens or underscores, and use of different top-level domains (such as .com, .net, or .org). By identifying these similarities, our tool can help organizations quickly spot potential threats and take appropriate action to mitigate them.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our similar domains and social accounts discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying fake websites and social accounts that mimic legitimate ones, you can take steps to protect against phishing attacks, malware, and other forms of cybercrime. This can include blocking access to these sites or reporting them to the appropriate authorities.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our tool can help you keep track of all similar domains and social accounts associated with your organization. This can be particularly useful for large organizations with numerous web properties and social accounts.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	Brand protection </span> <br/> <br/>  By identifying fake websites and social accounts that mimic legitimate ones, you can protect your brand reputation and prevent customers from being misled or defrauded by cyber criminals.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is worth noting that while our tool can help you identify potential threats, it is essential to take additional steps to protect against them. This can include implementing strong security policies, training employees on how to identify phishing attacks and fake social accounts, and regularly monitoring for suspicious activity.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; In conclusion, cyber criminals are constantly looking for ways to gain access to sensitive information, but our similar domains and social accounts discovery tool can help you identify and mitigate this risk. Contact us to learn more about our similar domains and social accounts discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default BrandDiscovery