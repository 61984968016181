import React from 'react'
import FooterHeaders from './FooterHeaders'

const InfoBlock = ({children, text}) => {
  return (
    <div className='mb-4 '>
        <FooterHeaders  text={text}/>
        {children}
    </div>
  )
}

export default InfoBlock