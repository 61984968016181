import domainAssets from "../src/assets/img/domain-asset.png"
import filtersAndButtons from "../src/assets/img/filters-and-buttons.png"
import detailsModal from "../src/assets/img/details-modal.png"


function DomainAssets() {
return (
    <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
        <h2 className="mb-3 blue-heading">Domain Assets Page</h2>
        <p>Domain Assets page contains your sub-domain assets inventory with additional information such as Ip address, Registrar, Creating Date, Expiry Date.</p>
        <p>To access the Domain Assets page, click Domain Assets under the Attack Surface drop-down on the sidebar.</p>
        <img className="w-md-75 ms-md-4 mb-4"  src={domainAssets} alt="Domain Assets Page" />
        <p>You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
        <img className="ms-md-4 my-3"  src={filtersAndButtons} alt="Filters And Buttons" />
        <p className="mt-4">Additionally, you can put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
        <img className="w-md-50 ms-md-4 my-1"  src={detailsModal} alt="Details Modal" />

    </div>
)
}

export default DomainAssets
