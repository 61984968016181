import React from 'react'
import protection from "../../assets/svg/protection-small.gif"

const NewSection = () => {
  return (
    <div className='container-fluid py-5 mb-5 ' style={{backgroundColor: "#2D4263" , color: "#fff" }}>
        <div className='row d-flex justify-content-center'>
            <div className='col-lg-5 me-5 ms-sm-5 ms-md-0'>
                <h2 className='px-2'>Stay ahead of potential threats with our innovative Attack Surface Management solution</h2>
                <p className='mt-5 px-5'>External Attack Surface Management helps identify and address potential vulnerabilities in your organization's external systems and networks, ensuring the safety and security of sensitive data and assets.</p>
            </div>
            <div className='col-lg-4 ms-5'>
                <img  src={protection} />
            </div>
        </div>
    </div>
  )
}

export default NewSection