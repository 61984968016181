
import totalAssets from "../src/assets/img/totalAssets.png"
import issuesSection from "../src/assets/img/issues-section.png"
import securityScoreSection from "../src/assets/img/security-score-section.png"
import sslSection from "../src/assets/img/ssl-section.png"
import latestScan from "../src/assets/img/latest-scan.png"
import techs from "../src/assets/img/techs.png"
import ports from "../src/assets/img/ports.png"
import latestAssets from "../src/assets/img/latest-asset.png"
import map from "../src/assets/img/maps.png"
import nav from "../src/assets/img/navbar.png"
import requestButton from "../src/assets/img/request-button.png"
import requestReport from "../src/assets/img/request-report.png"
import darkTheme from "../src/assets/img/dark-theme.png"
import lightTheme from "../src/assets/img/light-theme.png"

function Dashboard() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size overflow-auto">
            <h2 className="mb-3 blue-heading">Dashboard</h2>

            <p>After the successful completion of log-in, you will be redirected to your dashboard. The dashboard page contains a numerical summary of the findings as well as some details.</p>
            <p className="ms-lg-5 ms-2 mb-4">Total Assets Section This section contains information about the numerical values of detected domains assets, cloud assets, service assets, third-party password leaks, dark-web password leaks, issues and IP assets.</p>
            <img className=" ms-lg-5 ms-2" src={totalAssets} alt="Total Asset Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Issues Section</strong> This section contains information about the numerical values of detected issues grouped by their impact-level calculated by CVSS system.</p>
            <img className=" ms-lg-5 ms-2" src={issuesSection} alt="Issues Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Security Score Section </strong>This section contains information about the calculated security-scores based on the findings. You may click on the question mark in the right top corner to get more information about the scores.</p>
            <img className=" ms-lg-5 ms-2" src={securityScoreSection} alt="Security Score Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>SSL Monitoring Section</strong> This section contains information about expired and close-to-expire SSL certificates. You may click on the See Details text to get more information.</p>
            <img className=" ms-lg-5 ms-2" src={sslSection} alt="SSL Monitoring Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Latest Scan Plugins Section</strong> This section contains information about the latest vulnerability scan capabilities of Surfacemon and your state against the latest threats. You may click on the question mark to get more information.</p>
            <img className=" ms-lg-5 ms-2" src={latestScan} alt="Latest Scan Plugins Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Techs Section</strong> This section contains information about the discovered technologies in your current inventory.</p>
            <img className=" ms-lg-5 ms-2" src={techs} alt="Techs Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>TCP Ports Section</strong> This section contains information about the detected open TCP Ports on your current inventory.</p>
            <img className=" ms-lg-5 ms-2" src={ports} alt="TCP Ports Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Latest Assets Sections</strong> This section contains information about the latest discovered assets in your Inventory.</p>
            <img className=" ms-lg-5 ms-2" src={latestAssets} alt="Latest Assets Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Maps Section</strong> This section contains country information of detected IP assets in your inventory.</p>
            <img className=" ms-lg-5 ms-2" src={map} alt="Maps Section" />
            <p className="ms-lg-5 ms-2 mb-4 my-4"><strong>Navbar</strong> Navbar has multiple features such as limiting your results for a specific main domain, requesting reports, enabling dark-mode and log-out operation.</p>
            <p className="ms-lg-5 ms-2 mb-4 my-4">If your account has more than one main domain address, you can limit the results on the Portal by a specific domain by selecting a domain name from Company Domains drop-down menu.</p>
            <img className="w-50 ms-lg-5 ms-2" src={nav} alt="Navbar" />
            <p className="ms-lg-5 ms-2 mb-4 my-4">If you want to generate a report, you can click the Request report button on the top-right corner of the navbar.</p>
            <img className=" ms-lg-5 ms-2" src={requestButton} alt="Request Button" />
            <img className="w-50 ms-lg-5 ms-2" src={requestReport} alt="Request Report" />
            <p className="ms-lg-5 ms-2 mb-4 my-4">If you want to use a dark theme, you can click the moon icon on the top-right corner of the navbar.  Additionally, please click the sun icon on the same place for reverting it back to the white theme.</p>
            <div className="d-flex">
                <img className=" ms-lg-5 ms-2" src={darkTheme} alt="Dark Theme Button" style={{width: "100px"}} />
                <img className=" ms-2" src={lightTheme} alt="Light Theme Button" style={{width: "100px"}}/>
            </div>
        </div>
    )
}

export default Dashboard
