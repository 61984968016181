import cloudAssetsFilters from "../src/assets/img/cloud-assets-filters.png"
import cloudAssetsModal from "../src/assets/img/cloud-assets-modal.png"
import cloudAssetsPage from "../src/assets/img/cloud-assets-page.png"

function CloudAssets() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Cloud Assets Page</h2>
            <p>Cloud assets page contains your cloud service inventory such as Buckets, Registered Websites, Aws Apps with their related external links.</p>
            <img className="w-md-75" src={cloudAssetsPage} alt="Cloud Assets Page"  />
            <p className="my-3">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={cloudAssetsFilters} alt="Cloud Assets Filter And Buttons"  />
            <p className="my-3">Additionally, you can put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={cloudAssetsModal} alt="Cloud Assets Details Modal"  />
        </div>
    )
}

export default CloudAssets
