import React from 'react'
import Footer from '../MainPage/Footer/Footer'
import Header from '../MainPage/Header/Header'
import aboutUs from "../assets/svg/about-us.gif"
import "./AboutUs.css"
import Button from '../MainPage/Header/Button/Button'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const AboutUs = ({handleClick, isActive}) => {
  return (
    <div>
        <Header handleClick={handleClick} />
        <ToggleSidebar  isActive={isActive} />
        <div class="container-fluid h-100 d-flex justify-content-center ">
            <div className='row h-100 w-25 me-5 mt-5  gif'>
               <div className='col-lg-9 '>
               
                 <img src={aboutUs} />
                
            </div>
              
            </div>
            <div class="row w-50 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                <h2 className='mb-5' style={{color: "#0C007F"}}>ABOUT US</h2>
                <p className='mb-2'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Welcome to SurfaceMon External Attack Surface Management Tool! We're a team of cybersecurity experts dedicated to helping organizations protect against external threats.
                </p>
                <p className='mb-2'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Our tool is designed to provide a comprehensive view of an organization's external attack surface, including all assets that are publicly accessible and potentially vulnerable to attacks. With our tool, organizations can easily identify and prioritize assets that need to be secured, and implement effective measures to protect against external threats.
                </p>
                <p className='mb-2'>Some of the key features of our tool include:</p>
                <p className='mb-3'>  <span className='text-key'>Asset discovery and mapping:</span>  Our tool automatically discovers and maps all of an organization's publicly accessible assets, including web applications, servers, and IoT devices.</p>
                <p className='mb-3'> <span className='text-key'> Vulnerability scanning and assessment: </span> Our tool regularly scans assets for vulnerabilities and provides detailed reports on the potential risks and impact of each vulnerability.</p>
                <p className='mb-3'> <span className='text-key'> Threat intelligence: </span> Our tool integrates with leading threat intelligence sources to provide real-time information on the latest external threats and how to defend against them.</p>
                <p className='mb-4'>  <span className='text-key'>  Security remediation: </span> Our tool provides actionable recommendations for securing assets and mitigating potential risks, including best practices for patching vulnerabilities and implementing security controls.</p>
                <p className='mb-2'>To learn more about our tool and how it can help protect your organization against external threats, contact us at info@surfacemon.io or follow us on social media. We look forward to helping you secure your external attack surface!</p>
            
                <p>For other cyber security related service inqueries such as advanced penetration testing services, please visit our parent company website: <a href='https://centerondigital.com/'>centerondigital.com</a>  </p>
                <Button text="Contact Us" linkTo="contactUs" style="#fff" className="mx-auto mt-5"/>

            </div>
        </div>
        <Footer />
    </div>
  )
}

export default AboutUs