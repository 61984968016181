import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import Header from '../MainPage/Header/Header'
import Card from '../MainPage/SecondSection/Card/Card'
import "./Blog.css"
import Tags from './Tags'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const Blog = ({handleClick, isActive}) => {
  const cardData = [
    {
      header :  "Prioritize Vulnerabilities with External Attack Surface Management Tool",
      description: "  As cybersecurity threats continue to become more sophisticated and pervasive, organizations are increasingly turning to ethical hackers to help identify vulnerabilities in their systems and networks.  Ethical hackers, also known as white hat hackers, use their knowledge of computer systems and networks to identify and exploit vulnerabilities in an organization's cybersecurity defenses.",
      link: "blog/prioritize-vulnerabilities",
      date: "13.04.2023",
      tags: ["cybersecurity", "vulnerabilitymanagement", "easm"]
    },
    {
      header :  "An ethical Hacker’s Perspective on EASM",
      description: "  As cybersecurity threats continue to become more sophisticated and pervasive, organizations are increasingly turning to ethical hackers to help identify vulnerabilities in their systems and networks.  Ethical hackers, also known as white hat hackers, use their knowledge of computer systems and networks to identify and exploit vulnerabilities in an organization's cybersecurity defenses.",
      link: "blog/ethical-hacker-perspective",
      date: "05.04.2023",
      tags: ["cyber", "ethicalhacking", "easm"]
    },
    {
      header :  "Third-party Risks and External Attack Surface Management Tool ",
      description: "In today's interconnected business environment, organizations rely heavily on third-party vendors for various services, including cloud computing, payment processing, and supply chain management. However, these third-party vendors can also pose significant risks to cybersecurity. ",
      link: "blog/third-party-risks",
      date: "04.04.2023",
      tags: ["cyber", "thirdparty", "easm"]
    },
    {
      header :  "Danger of Shadow IT",
      description: "Shadow IT refers to the use of software, applications, and devices by employees without the approval or knowledge of the organization's IT department. While the use of technology can be a great way to increase productivity and efficiency, shadow IT can pose significant security risks.",
      link: "blog/danger-of-shadow-IT",
      date: "28.02.2023",
      tags: ["cyber", "shadowit", "easm"]
    },
    {
      header :  "Why we should not expose our Databases to the Internet",
      description: "Databases are an integral part of most organizations, holding sensitive information such as customer data, financial information, and intellectual property. However, with the rise of cloud computing and remote work, more and more databases are being exposed to the internet.",
      link: "blog/expose-databases-to-internet",
      date: "28.02.2023",
      tags: ["cyber", "database", "internet"]
    },
    {
      header :  "Difference between Penetration Test and External Attack Surface Management tools",
      description: "In today's world of increasing cyber threats, it's important for organizations to protect their digital assets. This includes not only securing internal networks and applications but also understanding and managing their external attack surface.",
      link: "blog/difference-between-pen-and-easm",
      date: "28.02.2023",
      tags: ["cyber", "penetrationtest", "easm"]
    },
    {
      header :  "Attack Surface Management",
      description: "External attack surface management is a cybersecurity strategy that focuses on identifying, assessing, and mitigating the vulnerabilities and threats that exist within an organization's external environment. This includes the internet-facing assets and systems that are accessible to external parties, such as websites, web applications, and cloud-based services.",
      link: "blog/what-is-attack-surface-management",
      date: "23.02.2023",
      tags: ["cyber", "attacksurface", "easm"]
    },
    {
      header : "Importance of EASM in Cloud Services",
      description: "The use of cloud services has become increasingly popular in recent years, as organizations look for ways to improve efficiency, reduce costs, and increase flexibility. However, the use of cloud services also introduces new risks, particularly if an organization fails to properly manage its attack surface.",
      link:"blog/risk-of-cloud-services",
      date: "23.02.2023",
      tags: ["easm", "cloud", "cloudsecurity"]
    },
    {
      header :  "Danger of Exposed Ports",
      description: "TCP ports are a fundamental component of the internet, serving as a means of communication between systems. However, if left exposed and unsecured, these ports can also provide a way for attackers to infiltrate a system and gain unauthorized access.",
      link: "blog/risk-of-ports",
      date: "23.02.2023",
      tags: ["easm", "ports", "tcpsecurity"]
    },
  ]
  const [filteredData, setFilteredData] = useState(cardData)
  const [selectedTag, setSelectedTag] = useState()
    

      useEffect(() => {
      } , [filteredData])

      const handleTagClick = (tag) => {
        setFilteredData(cardData)
      const result= cardData.filter(item => item.tags?.find(oneTag=> oneTag === tag))
       // const result= cardData.map(data => data?.tags?.filter(oneTag => oneTag === tag))
       setFilteredData(result)
       setSelectedTag(tag)
      }

      const handleTagClose = () => {
        setSelectedTag("")
        setFilteredData(cardData)
      }
    
  return (
    <div className='blog-container'>  
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <div className={`container-fluid mb-5 blog-container `}>
          <h1 className='text-center my-5 blue-text'>BLOG</h1>
          {selectedTag && 
          <div className='d-flex w-25 justify-content-end mb-4'><Tags isShow={true} handleTagClick={handleTagClick} handleTagClose={handleTagClose} text={selectedTag} /></div> }        
              <div className={`row animated-row my-element d-flex justify-content-center `}   >
                    {
                      filteredData.map((data, index) => (
                        <Card className="col-lg-3 me-3 blog-card bg-light" key={index} description={data.description}   header={data.header} link={data.link} date={data.date} tags={data.tags} handleTagClick={handleTagClick} />
                      ))
                    }
              </div>
            
    </div>
    </div>
  )
}

export default Blog