import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const SSLMonitoring = ({handleClick, isActive}) => {
  return (
    <div>  
      <Header handleClick={handleClick}/>
    <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>SSL Monitoring </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SSL certificates play a crucial role in ensuring secure communication between clients and servers over the internet. However, SSL certificates have an expiration date, after which they become invalid and can no longer be trusted. Therefore, it is crucial to monitor SSL certificates to detect expired ones, those that are close to expiring, and algorithmically weak ones.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;That's where our tool comes in. Our tool monitors SSL certificates to detect these issues and alerts the user. By offering this as a module, our tool can be integrated into existing cybersecurity infrastructure and can provide an extra layer of protection.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Expired or close-to-expiring SSL certificates can be a major vulnerability for an organization. Attackers can exploit this weakness to launch man-in-the-middle attacks or steal sensitive information. By monitoring SSL certificates with our tool, organizations can ensure that their SSL certificates are always up-to-date and secure.
                    </p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In addition to monitoring expiration dates, our tool also checks the strength of the encryption algorithm used in the SSL certificate. Weak encryption algorithms can be exploited by attackers to decrypt SSL traffic and steal sensitive information. By detecting algorithmically weak SSL certificates, our tool can help organizations improve their overall cybersecurity posture.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Overall, our SSL certificate monitoring module can provide organizations with an essential tool to help them ensure that their SSL certificates are always up-to-date and secure. By detecting expired, close-to-expiring, and algorithmically weak SSL certificates, our tool can help organizations protect themselves from potential cyber threats and maintain a strong security posture.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default SSLMonitoring