import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const ServiceDiscovery = ({handleClick, isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Service Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Discovering TCP services such as databases, remote access services, and file share services can be incredibly helpful for cyber security and asset monitoring. By identifying these services, organizations can better understand their network infrastructure and improve their overall security posture. Additionally, it can help organizations keep track of their assets and identify potential vulnerabilities before they are exploited.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To help organizations discover TCP services, we are offering a TCP service discovery tool as a module. This tool is designed to scan a network and identify all of the TCP services running on each device, including open ports and the specific services running on those ports. The tool uses a variety of techniques and algorithms to identify these services, including port scanning, banner grabbing, and protocol analysis.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our TCP service discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying the TCP services running on your network, you can better protect against potential vulnerabilities and attacks. Knowing which services are running can help you identify and patch known vulnerabilities, and it can also help you implement best practices for secure network configuration.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our TCP service discovery tool can help you keep track of all of the devices and services running on your network. This can be particularly useful for large organizations with numerous devices and services.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	    Compliance </span> <br/> <br/> Many compliance frameworks and regulations require organizations to maintain an inventory of all of their assets, including network devices and the services running on them. Our TCP service discovery tool can help you meet these requirements by identifying all of the TCP services running on your network and providing a comprehensive view of your network infrastructure.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, discovering TCP services is critical for cyber security and asset monitoring. Our TCP service discovery tool can help you identify all of the TCP services running on your network, improving your cyber security posture, asset monitoring, and compliance efforts. Contact us to learn more about our TCP service discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default ServiceDiscovery