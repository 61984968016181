import login from "../src/assets/img/login.png"

function LogIn() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <p>Please ensure that you establish your password by utilizing the link provided in the onboarding email correspondence. If the onboard email has not reached you yet, please contact us at info@surfacemon.io.</p>
            <p>•	Go to <a href="https://app.surfacemon.io/login" target="_blank" rel="noreferrer"> https://app.surfacemon.io/login</a> </p>
            <img  className="" src={login} alt="Login page"  />
        </div>
    )
}

export default LogIn
