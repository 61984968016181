import SideBar from "./Sidebar/Sidebar"
import "./KnowledgeBase.css"
import Header from "./MainPage/Header/Header"
import { Outlet } from "react-router-dom"
function KnowledgeBase() {
    return (
        <div className="app-layout">
            <SideBar />
            <Header page="Knowledge Base" />
            <>
                <Outlet />
            </>
        </div>
    )
}

export default KnowledgeBase
