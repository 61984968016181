import psswReset from "../src/assets/img/psswReset.png"


function PasswordReset() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size">
            <p>If you don’t remember your password, please select the "Don’t remember your password?" option located within the login interface or visit <a href="https://app-prd.surfacemon.io/forgetPassword " target="_blank" rel="noreferrer">https://app-prd.surfacemon.io/forgetPassword </a> page to initiate a password reset email. 
            You will receive an email from info@surfacemon.io address to change your password.</p>
            <img className="mt-4  " src={psswReset} alt="Reset Password Page" />
        </div>
    )
}

export default PasswordReset
