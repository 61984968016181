
import urgentScanModal from "../src/assets/img/urgent-scan-modal.png"


function UrgentScan() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Requesting an Urgent Scan</h2>
            <p>Even though Surfacemon scans are autonomous and continuous, you can request an urgent scan by clicking the <button className="bg-danger text-white rounded">Executive Summary</button> button on your Dashboard.</p>
           
            <img className="w-md-50 mb-4 ms-md-5" src={urgentScanModal} alt="Urgent Scan Modal"  />
            <p>	If there is no current scan running at the moment, a new scan will be started.</p>
          
        </div>
    )
}

export default UrgentScan
