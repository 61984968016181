import React from 'react'

const CardHeader = ({header}) => {
  return (
    <div className='container mt-3 '>
        <div className='row'>
            <div className='col-lg-12 card-header text-start'>
                    <h4> {header} </h4>
            </div>
        </div>
    </div>
  )
}

export default CardHeader