import React from 'react'

const Card = ({header, text, icon}) => {
  return (
    <div className='col-lg-3 d-flex align-items-center flex-column my-5 me-3'>
        <div className=' text-center'>
               <img  src={icon} style={{width: "75px", height:"75px"}} />
        </div>
        <h4 className='third-section-header my-3 fw-bold'>
            {header}
        </h4>
        <div className='paragraph text-center px-3'>
            {text}
        </div>
    </div>
  )
}

export default Card