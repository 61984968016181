import Footer from "../MainPage/Footer/Footer"
import Header from "../MainPage/Header/Header"
import ToggleSidebar from "../Sidebar/ToggleSidebar"

function ThreatIntelFeed({handleClick, isActive}) {
    return (
        <div>  
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} /> 
        <div class="container-fluid h-100 d-flex justify-content-center ">
           
            <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                <h2 className='mb-5' style={{color: "#0C007F"}}>Threat Intel Feed </h2>
                <p className='mb-4 paragraph'>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In the cyber realm, where threats evolve at breakneck speed, timely and accurate threat intelligence is the linchpin of robust defense strategies. Surfacemon stands as a beacon in this domain, offering advanced threat intelligence feeds that empower your organization to preemptively strike against potential cyber attacks.

                </p>
               
                <h4 className='mb-2'> Empower Your Cyber Defenses with Surfacemon Threat Intel Feeds</h4>

                <p className='mb-3 paragraph'>
                Surfacemon delivers meticulously curated threat intelligence, providing your security teams with the indicators of compromise (IOCs) they need to identify and neutralize threats before they impact your business. With Surfacemon's real-time threat intel feeds, your organization can:
                </p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Stay Ahead of Threats:</span> Receive up-to-the-minute IOCs, ensuring that your defense systems are primed to recognize and respond to emerging threats.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Enhance Incident Response: </span>Equip your security team with actionable data that enables rapid identification and remediation of security incidents.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Reduce Noise:</span>Cut through the clutter with high-fidelity intelligence, allowing your team to focus on genuine threats and minimize false positives.</p>
                <p className='mb-5 paragraph'> <span className='text-key'>Streamline Security Operations: </span> Integrate Surfacemon's feeds seamlessly into your existing security infrastructure, enhancing the effectiveness of your security information and event management (SIEM) systems, firewalls, and endpoint protection platforms.</p>
               
                <h4 className='mb-2'>Why Choose Surfacemon for Threat Intelligence?</h4>
                
                <p className='mb-3 paragraph'>
                Surfacemon is more than just a tool; it's a comprehensive solution that has been engineered with precision to address the needs of modern cybersecurity landscapes:
                </p>
                <p className='mb-3 paragraph'>  <span className='text-key'>Global Intelligence Coverage:</span> Drawing from a vast array of sources, Surfacemon provides a global perspective on the threat landscape, ensuring that no corner of the cyber world is beyond your view.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Expertly Vetted Data: </span>Our threat intel is rigorously analyzed by cybersecurity experts, providing you with reliable and actionable intelligence.</p>
                <p className='mb-3 paragraph'> <span className='text-key'>Tailored to Your Needs:</span>Surfacemon’s feeds are customizable to your organization’s unique profile, delivering relevance and specificity to bolster your defenses.</p>
                <p className='mb-5 paragraph'> <span className='text-key'>User-Centric Approach: </span> With an intuitive interface, Surfacemon ensures that the complex world of threat intelligence is accessible to your team at any level of expertise.</p>
               
                <h4 className='mb-3'>Seize the Strategic Edge with Surfacemon</h4>

                <p className='mb-4 paragraph'>Embrace the power of Surfacemon's threat intelligence and turn the tide against cyber adversaries. Your organization’s security apparatus will never be caught off guard when armed with the advanced foresight that Surfacemon provides.
                </p>
                <p className='mb-4 paragraph'>Venture into a new era of cybersecurity with Surfacemon at your side. 
                </p>
                <p className='mb-2 paragraph'>Protecting your digital assets begins with understanding the threats they face. With Surfacemon, you'll have the clarity and insight needed to defend your enterprise effectively. Secure your operations, fortify your defenses, and drive your business forward with confidence. Surfacemon is not just your tool; it's your shield in the digital age.

                </p>
            </div>
        </div>
        <Footer />
    </div>
    )
}

export default ThreatIntelFeed
