import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const CloudDiscovery = ({handleClick, isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} /> 
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Cloud Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Discovering cloud assets such as AWS S3 buckets, Google buckets, and Azure websites is becoming increasingly important for cyber security and asset monitoring. As more organizations move their data and applications to the cloud, it becomes more challenging to keep track of all of the assets that are in use. Additionally, cloud services often have different security configurations than traditional on-premises infrastructure, making it critical to understand what assets are being used and how they are being protected.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To help organizations discover cloud assets, we are offering a cloud asset discovery tool as a module. This tool is designed to scan the major cloud service providers and identify all of the assets associated with a given account or domain, including S3 buckets, Google buckets, and Azure websites. The tool uses a variety of techniques and algorithms to identify these assets, including API queries and DNS resolution.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our cloud asset discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying all of the cloud assets associated with a given account or domain, you can better protect against potential vulnerabilities and attacks. Knowing which assets are in use can help you identify and patch known vulnerabilities, and it can also help you implement best practices for secure cloud configuration.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our cloud asset discovery tool can help you keep track of all of the cloud assets associated with a given account or domain. This can be particularly useful for large organizations with numerous cloud assets.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	    Compliance </span> <br/> <br/> Many compliance frameworks and regulations require organizations to maintain an inventory of all of their assets, including cloud assets. Our cloud asset discovery tool can help you meet these requirements by identifying all of the cloud assets associated with a given account or domain and providing a comprehensive view of your cloud infrastructure.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, discovering cloud assets is critical for cyber security and asset monitoring. Our cloud asset discovery tool can help you identify all of the cloud assets associated with a given account or domain, improving your cyber security posture, asset monitoring, and compliance efforts. Contact us to learn more about our cloud asset discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default CloudDiscovery