import React , {useEffect, useState}from 'react'
import './AnimatedText.css'
import 'animate.css'
const AnimatedText = () => {
    const [loopNum, setLoopNum] = useState(0)  //index as to which word is currently displayed on the screen
    const [isDeleting, setIsDeleting] = useState(false)  //the word being typed or being deleted initially set to false cause we start by typing
    const toRotate = ["Domain Assets", 'Services' , 'Cloud Assets', 'Leaked Passwords']  //words that  will be displayed
    const rub = '|'
    const [text, setText] = useState('')  //text the showing right now, part of the word
    const [delta, setDelta] = useState(1000 - Math.random() * 50)  
    const period = 500;


    useEffect(() => {
        let ticker = setInterval(() => {
            tick()
        }, delta)

        return () => {clearInterval(ticker)}
    } , [text])

    const tick = ( ) => {
        let i = loopNum % toRotate.length
        let fullText = toRotate[i];
        let updatedText = isDeleting ? fullText.substring(0, text.length-1) : fullText.substring(0, text.length + 1)

        setText(updatedText)

        if(isDeleting) {
            setDelta(prevDelta => prevDelta /2)
        }

        if(!isDeleting && updatedText === fullText) {
            setIsDeleting(true)
            setDelta(period)
        }
        else if(isDeleting && updatedText === ''){
            setIsDeleting(false)
            setLoopNum(loopNum + 1)
            setDelta(500)
        }
    }
  return (
    <section className='AnimatedText' id='home'>
        <div className='container mb-5'>
            <div className='row align-items-center '>
                <div className='col-12'>
                        <div className='text-center'>
                            <h1>    
                                <span className='wrap'> {text}{rub}  </span>
                            </h1>
                        </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default AnimatedText