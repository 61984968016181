import companyProfileInvite from "../src/assets/img/company-profile-invite.png"
import companyProfilePage from "../src/assets/img/company-profile-pages.png"
import taskStatus from "../src/assets/img/task-status.png"

function CompanyProfile() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Company Profile Page</h2>
            <p>Company Profile page contains information about your portal users, defined main domains, assigned tasks.</p>
            <img className="w-md-75" src={companyProfilePage} alt="Company Profile Page"  />
            <p>You can invite new users to your Surfacemon Portal by clicking  <button className="blue-bg text-white rounded">Invite User</button> button on the “Users Lists” section.</p>
            <p>Additionally, you can select which pages invited user will have access to by clicking check boxes.</p>
            <img className="w-md-75 mb-5" src={companyProfileInvite} alt="Invite User Modal"  />
            <p>In the Task Status section, you can see currently assigned tasks and their statuses. </p>
            <img  className="w-md-25" src={taskStatus} alt="Task Status"  />
        </div>
    )
}

export default CompanyProfile
