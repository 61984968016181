import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const DarkWebDiscovery = ({handleClick, isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Darkweb Password Leak Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;One of the most significant threats to cyber security is password leaks. Password leaks can occur when user account information is exposed through a data breach, social engineering attack, or other means. These leaks can lead to identity theft, account takeover, and other forms of cybercrime. To help organizations mitigate this risk, we are offering a password leak discovery tool as a module. This tool is designed to scan public database leaks and dark-web marketplaces for emails and identify password leaks associated with an organization.
                    </p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our password leak discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying password leaks associated with an organization, you can take steps to protect against account takeover and other forms of cybercrime. This can include resetting passwords, implementing multi-factor authentication, and educating users about the risks of password reuse and weak passwords.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our password leak discovery tool can help you keep track of all password leaks associated with your organization. This can be particularly useful for large organizations with numerous user accounts.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	    Compliance </span> <br/> <br/>  Many compliance frameworks and regulations require organizations to maintain an inventory of all security incidents, including password leaks. Our password leak discovery tool can help you meet these requirements by identifying all password leaks associated with your organization and providing a comprehensive view of your security incidents.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is worth noting that while our tool can help you identify password leaks associated with your organization, it is essential to take additional steps to protect against password leaks. This can include implementing strong password policies, training employees on how to avoid social engineering attacks, and regularly monitoring for suspicious activity.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, password leaks are a significant threat to cyber security, but our password leak discovery tool can help you identify and mitigate this risk. Contact us to learn more about our password leak discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default DarkWebDiscovery