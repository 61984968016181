import React from 'react'
import Number from './Number'

const HowItWorksItem = ({text, num}) => {
  return (
    <div className='col-lg-4 d-flex justify-content-center align-items-center pb-5'>
        <Number  num={num} />
        <p className='ms-3 pe-3 text-start  explanation'> {text}  </p>
    </div>
    
  )
}

export default HowItWorksItem