import DarkWebFilters from "../src/assets/img/dark-web-filters.png"
import DarkWebModal from "../src/assets/img/dark-web-modal.png"
import DarkWebPage from "../src/assets/img/dark-wweb-page.png"

function DarkWebLeaks() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Dark-web Leaks Page</h2>
            <p>Dark-Web Leaks page contains your inventory of compromised emails by malware(s) with some additional information such as Dark-web Marketplace name, Malware Type, Compromised device, Email and Password information. Please see additional items section for more definitions of Dark-Web Leaks.</p>
            <img className="w-md-75" src={DarkWebPage} alt="Dark-web Leaks  Page"  />
            <p className="mt-5 ">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={DarkWebFilters} alt="Dark-web Leaks  Filter And Buttons"  />
           <p className="my-5">Additionally, you can get more information about the issue, remediations, put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={DarkWebModal} alt="Dark-web Leaks  Details Modal"  />
        </div>
    )
}

export default DarkWebLeaks
