import issuesPocFilters from "../src/assets/img/issues-poc-filters.png"
import issuesPocModal from "../src/assets/img/issues-poc-modal.png"
import issuesPocPage from "../src/assets/img/issues-poc-page.png"
import issuesPocInvite from "../src/assets/img/issues-poc-invite.png"

function IssuesByPoc() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Issues By PoC</h2>
            <p>Issues by PoC page contains your inventory of exploitable issues with additional information such as CVE, Severity, Status and Assignee.</p>
            <img className="w-md-75" src={issuesPocPage} alt="Issues by PoC Page"  />
            <p className="mt-5 ">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img className="w-md-75" src={issuesPocFilters} alt="Issues by PoC Filter And Buttons"  />
            <p className="mt-5">You can assign issues to your team-mates by using the +   icon under the Assignee column. </p>
            <img  className="w-md-25" src={issuesPocInvite} alt="Issues by PoC Assignee"  />
            <p className="my-5">Additionally, you can get more information about the issue, remediations, put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={issuesPocModal} alt="Issues by PoC Details Modal"  />
        </div>
    )
}

export default IssuesByPoc
