import React, { useRef } from 'react'
import { useState } from 'react'
import Footer from '../MainPage/Footer/Footer'
import Header from '../MainPage/Header/Header'
import "./ContactUs.css"
import contactUs from "../assets/svg/contactUs.gif"
import ToggleSidebar from '../Sidebar/ToggleSidebar'



const ContactUs = ({handleClick, isActive}) => {

    const form = useRef()

    const formInitialDetails = {
        name : "",
        lastName : "",
        jobTitle:  "",
        companyName : "",
        workEmail :"",
        phone: ""
    }


    const [formDetails, setFromDetails] = useState(formInitialDetails)
    const [buttonText , setButtonText] = useState('Contact')
    const [message, setmessage] = useState()
    const [show, setShow] = useState(false)

    const onFormUpdate = (category, value) => {
        setFromDetails({
            ...formDetails,
            [category] : value
        })
    }

    const sendEmail = (e) => {
        e.preventDefault()

        setButtonText('Sending...')
        async function sendRequest() {
           try{ const res = await fetch(`https://public.surfacemon.io/api/contactUs`, {
              method: "POST",
              body: JSON.stringify(formDetails),
              headers: {
                'Content-Type': 'application/json',
                'Content-Encoding' : "gzip",
              },
            });
            res.text().then(res => {
              
              setButtonText("Sended")
              setmessage(res)
              setFromDetails(formInitialDetails)
            });
            }catch(e) {console.log(e)}
        } 

        if(formDetails){
            sendRequest()
        }
    }
    
    
  return (
    <>
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <div className="container-fluid h-100 d-flex justify-content-center align-items-center">
            <div className='row h-100 w-25 me-5 gif'>
               <div className='col-lg-2'>
                 <img src={contactUs} />
                 <h1 style={{color: "#0C007F"}}>GET IN TOUCH WITH US</h1>
            </div>
        
            </div>
        
            <div className="row w-50 content-wrapper form-wrapper pt-5 d-flex justify-content-center align-items-center">
                <h2 className='mb-4'>CONTACT US</h2>
                <div className="">
    
                    <form className="row g-3 "  ref={form} onSubmit={sendEmail}>
                    <div className={`col-md-6 input-container mb-3 `}>
                        <label className="input-label text-start" htmlFor="inputName4"  >Name*
                            <input type="text" className={`form-control input js_form-input `} id="inputName1"  value={formDetails.name} name='user_name'  onChange={(e) => onFormUpdate('name' , e.target.value)}  />
                            
                        </label>
                    </div>
                    <div className={`col-md-6 input-container mb-3 `}>
                        <label className="input-label text-start" htmlFor="inputName4"  >Last Name*
                            <input type="text" className={`form-control input js_form-input `} id="inputName2" value={formDetails.lastName} name="user_lastname"  onChange={(e) => onFormUpdate('lastName' , e.target.value)}  />
                        </label>
                    </div>
                        <div className={`col-12 mb-3 input-container `}>
                        <label className="input-label col-10 text-start" htmlFor="inputName4"  >Job Title*
                            <input type="text" className={`form-control input js_form-input `} id="inputName3" value={formDetails.jobTitle} name='job_title'  onChange={(e) => onFormUpdate('jobTitle' , e.target.value)} />
                        </label>
                        </div>
                        <div className={`col-12 input-container `}>
                        <label className="input-label col-10 text-start" htmlFor="inputName4"  >Company Name*
                            <input type="text" className={`form-control input js_form-input `} id="inputName4"  value={formDetails.companyName} name='company_name'  onChange={(e) => onFormUpdate('companyName' , e.target.value)}   />
                        </label>
                        </div>
                        <div className={`col-12 input-container `}>
                        <label className="input-label col-10 text-start" htmlFor="inputName4"  >Work Email*
                            <input type="text" className={`form-control input js_form-input `} id="inputName4"  value={formDetails.workEmail} name='work_email'  onChange={(e) => onFormUpdate('workEmail' , e.target.value)} />
                        </label>
                        </div>
                        <div className={`col-12 `}>
                        <label className="input-label col-10 text-start" htmlFor="inputName4"  >Phone Number*
                            <input type="text" className={`form-control input js_form-input `} id="inputName5" value={formDetails.phone} name='phone_number'  onChange={(e) => onFormUpdate('phone' , e.target.value)}  />
                        </label>
                        </div>
                        <div className="col-2 w-100 justify-content-end me-5 d-flex align-items-center">
                            <button type="submit" className="btn  login-button me-5  mb-5"   style={{backgroundColor: "#0C007F" , color: "#fff"}}  > {buttonText}  </button>
                        </div>
                    </form>
                    <div>

                    </div>
                    
                </div>
                    {message ? <p className='message w-25 d-flex justify-content-center'> {message}  </p> : ""}

                <p  style={{color: "rgb(175, 180, 184)"}} ><strong>Company Address:</strong> <span> Maslak Mah. AOS 55 sk.42 Maslak B BLOK No:4/542 SARIYER, İSTANBUL/TURKEY </span> </p> 
                <p  style={{color: "rgb(175, 180, 184)"}} ><strong>Company Phone:</strong><span> +90 212 924 24 16 </span> </p> 
       
                
            </div>
        </div>
        <Footer />
    </>
  )
}

export default ContactUs