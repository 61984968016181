function  OnboardingRequirements() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size">
            <h2 className="mb-3 blue-heading">Onboarding Requirements</h2>
            <p>The Surfacemon EASM platform is designed for streamlined deployment, necessitating minimal informational input and obviating the need for any installation, thereby facilitating a prompt commencement of system operations. 
             
            </p>
            <p className="mt-3 mb-5"> The followings are information required by the Surfacemon Platform:</p>
            <table class="table">
            <thead className="blue-bg text-white">
                <tr>
                <th scope="col">#</th>
                <th scope="col">Information</th>
                <th scope="col">Type</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <th scope="row">1</th>
                <td>Domain Assets</td>
                <td>Required</td>
                </tr>
                <tr>
                <th scope="row">2</th>
                <td>IP Addresses</td>
                <td>Optional</td>
                </tr>
                <tr>
                <th scope="row">3</th>
                <td>Portal Users</td>
                <td>Required</td>
                </tr>
            </tbody>
            </table>
        </div>
    )
}

export default OnboardingRequirements
