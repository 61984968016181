import similarPage from "../src/assets/img/similar-page.png"
import similarFilters from "../src/assets/img/similar-filters.png"

function Similar() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Similar Domains / Similar Social Account Pages</h2>
            <p>Similar Domains / Similar Social Account pages contains information about the domains and social accounts that are like yours. Please refer to the additional items section for more information.</p>
            <img className="mb-5" src={similarPage} alt="Similar Domains Page"  />
            <p>You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={similarFilters} alt="Similar Domains Filters and Buttons"  />
        </div>
    )
}

export default Similar
