


function AdditionalItems() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Additional Items</h2>
            <p> <strong>Third Party Leaks:</strong>  Third party leaks on Surfacemon refer to corporate email leaks on publicly leaked databases of third-party applications. E.g. LinkedIn Breach. Surfacemon continuously monitors such leaks to detect your corporate email addresses.</p>
            <p> <strong>Dark-web Leaks:</strong> Dark web leaks on Surfacemon refer to compromised email leaks by using stealer computer viruses such as Raccoon Stealer, Vidar stealer. Surfacemon continuously monitors Dark-Web credential marketplaces in order to detect and purchase such computer virus logs before malicious entities and provides you with detailed information to conduct an investigation.</p>
           
          
        </div>
    )
}

export default AdditionalItems
