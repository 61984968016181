import React from 'react'
import { Link } from 'react-router-dom'

const FooterInfoText = ({text, link}) => {
  
  return (
    
        <p className=' my-1 footer-info-text'>
                <Link className='d-block footer-info-link ' to={`/${link}`}>
                    {text}
                </Link>
        </p> 
  )
}

export default FooterInfoText