import ProfilePagePage from "../src/assets/img/profile-page.png"
import changePassword from "../src/assets/img/change-password.png"
import reportsModal from "../src/assets/img/reports-modal.png"


function ProfilePage() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Profile Page</h2>
            <p>Profile page contains information about the tasks assigned to you and reports requested by you. Additionally, you can update your password by using the Profile page.</p>
            <img src={ProfilePagePage} alt="Profile Page"  />
            <p>You can update your password by clicking <button className="blue-bg text-white rounded">Change Password</button>  button. </p>
            <img className=" mb-4" src={changePassword} alt="Password Update"  />
            <p>Additionally, you can see and download reports requested by you by clicking the  <button className="blue-bg text-white rounded">Reports</button>                    button. </p>
            <img className="w-md-50" src={reportsModal} alt="Reports Modal"  />
        </div>
    )
}

export default ProfilePage
