import initialContact from "../src/assets/img/initial-contact.png"

function InitialContact() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size">
            <p>After your environment is successfully deployed, you will be receiving and email from info@surfacemon.io address to set your password and start usage.</p>
            <img className=" mt-5" src={initialContact} alt="Initial Contact" />
        </div>
    )
}

export default InitialContact
