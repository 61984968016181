import React from 'react'
import Button from '../MainPage/Header/Button/Button'
import security from "../assets/svg/security-sm.gif"
import "../MainPage/FirstSection/FirstSection.css"
const FirstSection = () => {
  return (
    <div className='container first-section mb-4 align-items-center d-flex vh-100'>
    <div className='row mb-5'>
        <div className='col-lg-6 col-xl-8 col-md-5 mt-5'>
            <h1 className='mb-5 title-style'>See Unknown Risks Within & Beyond Your Known Network Ranges. </h1>
           
            <div className='d-flex w-100 justify-content-center'>
                <Button  text='Get a Demo' style="#fff" linkTo="contactUs"  />
               
            </div>
        </div>
        <div className='col-lg-5 col-xl-4 col-md-2 mt-5'>
            <img className='security-gif' src={security}/>
        </div>
    </div>

</div>
  )
}

export default FirstSection