import React from 'react'
import Card from '../../MainPage/ThirdSection/Card'
import cloud from "../../assets/svg/cloud.svg"
import safety from "../../assets/svg/Safety_Line.svg"
import team from "../../assets/svg/team.svg"
import bug from "../../assets/svg/Bug_Line.svg"
import key from "../../assets/svg/Key_Line.svg"
import thumbsUp from "../../assets/svg/thumbs.svg"
import alert from "../../assets/svg/Alert_Line.svg"
import support from "../../assets/svg/support.svg"


const ThirdSection = () => {
  const data = [
    {
      icon: cloud,
      header: 'Asset Discovery',
      text: "Automatically discover and map all of an organization's publicly accessible assets, including web applications, servers, and IoT devices."
    },
    {
      icon: bug,
      header: 'Vulnerability Scanning',
      text: 'Regularly scan assets for vulnerabilities. Patch vulnerabilities before attackers exploit it.'
    },
    {
      icon: key,
      header: 'Leak Intelligence',
      text: 'Get real-time information on the latest password leaks related to your organization. Secure them before malicious actors use them.'
    },
    {
      icon: thumbsUp,
      header: 'Intuitive user interface',
      text: "user-friendly interface that allows users to easily access and manage the tool's features and functionality, making it simple to use for even the most non-technical users."
    },
    {
      icon: support,
      header: 'Expert Customer Support',
      text: 'Our team of cybersecurity experts is available to provide assistance and support whenever you need it, ensuring that you have the help you need to effectively use and benefit from our tool.'
    },
    {
      icon: alert,
      header: 'Real Time Alerting',
      text: 'Real-time alerting and notification capabilities to help your organizations quickly identify and respond to potential security incidents.'
    },
  ]
  return (
    <div className='container third-section'>
      <div className='row d-flex justify-content-center'>
            <h2 className='mt-5'>Key Features</h2>
        {
          data.map(dataItem => (
              <Card text={dataItem.text} icon={dataItem.icon} header={dataItem.header} />
          ))
        }
      </div>
    </div>
  )
}

export default ThirdSection