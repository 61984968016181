import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const TechDiscovery = ({handleClick, isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Web Technology Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Knowing the technologies used in a website can be incredibly helpful for cyber security and asset monitoring. By understanding the underlying technologies, organizations can better protect their websites against potential vulnerabilities and attacks. Additionally, it can help organizations keep track of their web properties and identify any outdated or unsupported technologies that may pose a security risk.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To help organizations identify the technologies used in their websites, we are offering a technology discovery tool as a module. This tool is designed to scan a website and identify all of the technologies used, including web frameworks, content management systems, programming languages, and more. The tool uses a variety of techniques and algorithms to identify these technologies, including fingerprinting, code analysis, and network traffic analysis.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our technology discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying the technologies used in your websites, you can better protect against potential vulnerabilities and attacks. Knowing which technologies are being used can help you identify and patch known vulnerabilities, and it can also help you implement best practices for secure development.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our technology discovery tool can help you keep track of all of your organization's web properties and identify any outdated or unsupported technologies that may pose a security risk. This can be particularly useful for large organizations with numerous websites and web applications.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	    Compliance </span> <br/> <br/>Many compliance frameworks and regulations require organizations to maintain an inventory of all of their assets, including web properties and the technologies used in them. Our technology discovery tool can help you meet these requirements by identifying all of the technologies used in your websites and providing a comprehensive view of your web properties.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, understanding the technologies used in your websites is critical for cyber security and asset monitoring. Our technology discovery tool can help you identify all of the technologies used in your websites, improving your cyber security posture, asset monitoring, and compliance efforts. Contact us to learn more about our technology discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default TechDiscovery