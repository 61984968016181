
import reportModal from "../src/assets/img/report-modal.png"


function GeneratingReports() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Generating Reports</h2>
            <p>In Surfacemon you can generate two types of reports. Executive Summary and Overall Report.</p>
            <p className="ms-md-5 ms-2">Executive Summary To generate an Executive Summary report, you can click  <button className="blue-bg text-white rounded">Executive Summary</button>  button on your dashboard.</p>
            <p className="ms-md-5 ms-2">Overall Report  To generate an overall detailed report, you can click the <button className="blue-bg text-white rounded">Request Report</button> button on the top-right corner of the navbar.</p>
            
            <img className=" mb-4 ms-md-5" src={reportModal} alt="Report Generation Modal"  />
            <p>	When your detailed report is ready, you will be receiving an email from info@surfacemon.io</p>
          
        </div>
    )
}

export default GeneratingReports
