
import CVEPage from "../src/assets/img/cve-page.png"

function CVENews() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">CVE News Page</h2>
            <p>CVE News page contains newly registered CVEs to the <a href="https://cve.org/" target="_blank" rel="noreferrer">CVE Organization.</a> </p>
            <img className="mt-5 w-md-75" src={CVEPage} alt="CVE News Page"  />
        </div>
    )
}

export default CVENews
