import React from 'react'
import Navitem from './Navİtem'
import "./Sidebar.css"
import {  useNavigate } from 'react-router-dom'
const SideBar = ({isActive}) => {

  const navigate = useNavigate();

  const goToKnowledgeBase = () => {
    navigate('/knowledge-base');
  };

  return (
    <aside className={`sidebar-nav-wrapper ps-sm-2 ${isActive ? "active" :""}`}>
        <h4 className='text-black text-start' role='button' onClick={goToKnowledgeBase}>Knowledge Base</h4> 
        <nav className="sidebar-nav mt-5 h-100">
          <ul className='d-flex justify-content-between ps-0 flex-column h-100'>
            <div>
                <h2 className="fs-lg-5 text-dark text-start ps-md-2 header-size-sm"  >Onboarding Process</h2>
                  <Navitem text="Requirements"  linkTo="knowledge-base/onboarding-requirements" className="header-size-sm" padding="ps-md-4 ps-2 text-secondary"/>
                  <Navitem text="Initial Contact"  linkTo="knowledge-base/initial-contact" className="header-size-sm" padding="ps-md-4 ps-2 text-secondary"/>
                <h2 className="fs-lg-5 text-dark text-start ps-md-2 header-size-sm"  >Accessing the System</h2>
                  <Navitem text="Log-in"  linkTo="knowledge-base/log-in" className="header-size-sm" padding="ps-md-4 ps-2 text-secondary"/>
                  <Navitem text="Password Reset"  linkTo="knowledge-base/password-reset" className="header-size-sm" padding="ps-md-4 ps-2 text-secondary"/>
                <h2 className="fs-lg-5 text-dark text-start ps-md-2 header-size-sm"  >Getting Familiar</h2>

                  <Navitem text="Dashboard" className="text-size-sm text-size-md" linkTo="knowledge-base/dashboard" padding="ps-md-4 ps-2 text-secondary" />
                  <h5 className='text-start ps-md-4 ps-2 text-secondary text-size-sm text-size-md'  >Attack Surface</h5>
                    <Navitem text="Domain Assets"  linkTo="knowledge-base/domain-assets-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Web Technologies "  linkTo="knowledge-base/web-techs-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Services"  linkTo="knowledge-base/services-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Cloud Assets"  linkTo="knowledge-base/cloud-assets-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="IP Assets"  linkTo="knowledge-base/ip-assets-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Certificates"  linkTo="knowledge-base/certificates-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                  <h5 className='text-start ps-md-4 ps-2 text-secondary text-size-sm text-size-md' >Issues</h5>

                    <Navitem text="Issues by PoC"  linkTo="knowledge-base/issues-by-PoC" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Issues by Tech"  linkTo="knowledge-base/issues-by-tech" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                  <h5 className='text-start ps-md-4 ps-2 text-secondary text-size-sm text-size-md'>Password Leaks</h5>
                    <Navitem text="Third-Party Leaks"  linkTo="knowledge-base/third-party-leaks" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Dark-Web Leaks"  linkTo="knowledge-base/dark-web-leaks" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                  <h5 className='text-start ps-md-4 ps-2 text-secondary text-size-sm text-size-md'>Threat Intelligence</h5>

                    <Navitem text="IOC Intelligence"  linkTo="knowledge-base/ioc-intelligence-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="CVE News"  linkTo="knowledge-base/cve-news-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Similar Domains/Similar Social Accounts"  linkTo="knowledge-base/similar-domains" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-start text-secondary"/>
                  <h5 className='text-start ps-md-4 ps-2 text-secondary text-size-sm text-size-md' >Management</h5>

                    <Navitem text="Company Profile"  linkTo="knowledge-base/company-profile-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                    <Navitem text="Profile"  linkTo="knowledge-base/profile-page" className="text-size-sm text-size-md" padding="ps-md-5 ps-3 text-secondary"/>
                  <Navitem text="Generating Reports"  linkTo="knowledge-base/generating-reports" padding="ps-md-4 ps-2 text-muted" className="text-size-sm text-size-md" />
                  <Navitem text="Requesting Scan"  linkTo="knowledge-base/urgent-scan" padding="ps-md-4 ps-2 text-muted" className="text-size-sm text-size-md" />
                  <Navitem text="Additional Items"  linkTo="knowledge-base/additional-items" padding="ps-md-4 ps-2 text-muted" className="text-size-sm text-size-md" />
                 
            </div>
          </ul>
        </nav>
      </aside>
  )
}

export default SideBar