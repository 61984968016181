import React from 'react'
import FirstSection from './FirstSection'
import Header from '../MainPage/Header/Header'
import SecondSection from './SecondSection/SecondSection'
import ThirdSection from './ThirdSection/ThirdSection'
import FourthSection from './FourthSection/FourthSection'
import FAQSection from './Faq/FAQSection'
import Footer from "../MainPage/Footer/Footer"
import ToggleSidebar from '../Sidebar/ToggleSidebar'


const PlatformOverview = ({isActive, handleClick}) => {


  return (
    <div>
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <FirstSection />
        <SecondSection />
        <ThirdSection />
        <FourthSection />
        <FAQSection />
        <Footer />
    </div>
  )
}

export default PlatformOverview