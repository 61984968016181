import React from 'react'
import Header from '../MainPage/Header/Header'
import Footer from '../MainPage/Footer/Footer'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const SubdomainDiscovery = ({handleClick, isActive}) => {
  return (
    <div>  
            <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
            <div class="container-fluid h-100 d-flex justify-content-center ">
               
                <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
                    <h2 className='mb-5' style={{color: "#0C007F"}}>Subdomain Discovery </h2>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Subdomain discovery is a critical component of any organization's cyber security and asset monitoring efforts. Subdomains are essentially separate domains that are part of a larger parent domain, and they are often used to serve specific functions or host different services or applications. However, they can also be used maliciously by attackers to gain access to an organization's network or sensitive information.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To help organizations identify and monitor their subdomains, we are offering a subdomain discovery tool as a module. This tool is designed to scan a given domain and identify all of its subdomains, including those that may be hidden or not readily apparent. The tool uses a variety of techniques and algorithms to identify subdomains, including brute-force scanning, DNS queries, and web crawling.
                    </p>
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The benefits of using our subdomain discovery tool include:
                    </p>
                    <p className='mb-3 paragraph'>  <span className='text-key'>1	Improved cyber security:   </span> <br/> <br/>By identifying all of your subdomains, you can better protect your organization against potential cyber attacks. Attackers often target subdomains as a way to gain access to an organization's network or sensitive data, and by identifying and monitoring these subdomains, you can quickly detect and respond to any malicious activity.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'> 2	Asset monitoring:  </span><br/> <br/>Our subdomain discovery tool can help you keep track of all of your organization's assets and identify any new subdomains that may have been added without your knowledge. This can be particularly useful for large organizations with numerous subdomains and web properties.</p>
                    <p className='mb-3 paragraph'> <span className='text-key'>3	    Compliance </span> <br/> <br/>  Many compliance frameworks and regulations require organizations to maintain an inventory of all of their assets, including subdomains. Our subdomain discovery tool can help you meet these requirements by identifying all of your organization's subdomains and providing a comprehensive view of your web properties.</p>
                   
                    <p className='mb-2 paragraph'>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In conclusion, subdomain discovery is a critical component of any organization's cyber security and asset monitoring efforts. Our subdomain discovery tool can help you identify and monitor all of your organization's subdomains, improving your cyber security posture, asset monitoring, and compliance efforts. Contact us to learn more about our subdomain discovery module and how it can benefit your organization.
                    </p>
                    
                </div>
            </div>
            <Footer />
        </div>
  )
}

export default SubdomainDiscovery