import webTech from "../src/assets/img/web-tech-page.png"
import webTechFilters from "../src/assets/img/web-tech-filters.png"
import webTechModal from "../src/assets/img/webtech-modal.png"


function WebTechnologies() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">Web Technologies Page</h2>
            <p>Web Technologies page contains your technologies inventory with additional info such as HTTP Codes, Technology versions and Subdomains.</p>
            <img className="w-md-75 mb-4 ms-md-5" src={webTech} alt="Web Technologies Page" />
            <p>You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img  src={webTechFilters} alt="Web Technologies Filters And Buttons" />
            <p className="my-4">Additionally, you can put comments and edit Certainty and Priority information of a finding by clicking the “magnifier”   icon on the right-most side of the rows.</p>
            <img className="w-md-50" src={webTechModal} alt="Web Technologies Details Modal" />
        </div>
    )
}

export default WebTechnologies
