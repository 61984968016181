import React from 'react'
import 'animate.css'; 
import Slide from 'react-reveal/Slide';
import Card from './Card/Card'

const SecondSection = () => {

  const cardData = [
    {
      header :  "Attack Surface Management",
      description: "External attack surface management is a cybersecurity strategy that focuses on identifying, assessing, and mitigating the vulnerabilities and threats that exist within an organization's external environment. This includes the internet-facing assets and systems that are accessible to external parties, such as websites, web applications, and cloud-based services.",
      link: "blog/what-is-attack-surface-management"
    },
    {
      header : "Importance of EASM in Cloud Services",
      description: "The use of cloud services has become increasingly popular in recent years, as organizations look for ways to improve efficiency, reduce costs, and increase flexibility. However, the use of cloud services also introduces new risks, particularly if an organization fails to properly manage its attack surface.",
      link:"blog/risk-of-cloud-services"
    },
    {
      header :  "Danger of Exposed Ports",
      description: "TCP ports are a fundamental component of the internet, serving as a means of communication between systems. However, if left exposed and unsecured, these ports can also provide a way for attackers to infiltrate a system and gain unauthorized access.",
      link: "blog/risk-of-ports"
    }
  ]

 
  
  

  return (
    <>
        <div className={`container mb-5 `}>
          <h1 className='text-center my-5 blue-text'>Our Greatest & Latest</h1>        
          <Slide up>
              <div className={`row animated-row my-element d-flex justify-content-center `}   >
                    {
                      cardData.map(data => (
                        <Card className="second-section_card" description={data.description}   header={data.header} link={data.link} />
                      ))
                    }
              </div>
            
            </Slide>
    </div>
   
</>
  )
}

export default SecondSection