import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom'
const NavitemHasChildren = ({text, list, id, isActive}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
      setIsOpen(!isOpen);
  };
  return (
    <li className="nav-item nav-item-has-children text-start">
              <a
                href="/"
                className="collapsed"
                data-bs-toggle="collapse"
                data-bs-target={`#${id}`}
                aria-controls={id}
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={toggleDropdown}

              >
                <span className="text">{text} <i className={`bi ${isOpen ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`}></i></span>
              </a>
              <ul id={id} className="collapse dropdown-nav">
              {
                    list.map(item => (
                        <li className='text-start'>
                        <Link to={item.linkTo}> {item.content} </Link>
                    </li>
                    ))
                }
              </ul>
        </li>
  )
}

export default NavitemHasChildren