import React from 'react'
import Footer from '../MainPage/Footer/Footer'
import Header from '../MainPage/Header/Header'
import ToggleSidebar from '../Sidebar/ToggleSidebar'

const RiskCloudServices = ({handleClick, isActive}) => {
  return (
    <div>
    <Header handleClick={handleClick}/>
    <ToggleSidebar  isActive={isActive} />
    <div class="container-fluid h-100 d-flex justify-content-center ">
 
        <div class="row w-75 content-wrapper  pt-5 d-flex form-wrapper px-3 pb-5 justify-content-center align-items-center text-start">
            <h2 className='mb-5' style={{color: "#0C007F"}}>Importance of EASM in Cloud Services</h2>
            <p className='mb-2'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The use of cloud services has become increasingly popular in recent years, as organizations look for ways to improve efficiency, reduce costs, and increase flexibility. However, the use of cloud services also introduces new risks, particularly if an organization fails to properly manage its attack surface.
            </p>
            <p className='mb-2'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;An attack surface is the total sum of vulnerabilities and weaknesses that an organization has, both internally and externally. This includes internet-facing assets, such as websites and web applications, as well as systems and networks that are accessible from the internet. In the case of cloud services, the attack surface includes not only the organization's own assets, but also the assets of the cloud service provider.
            </p >
            <p className='mb-2'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Properly managing the attack surface is crucial to the security of an organization. By identifying and addressing vulnerabilities and threats, an organization can significantly reduce its risk of cyber attacks and protect its sensitive data and assets. However, if an organization fails to properly inventory its attack surface, it may be vulnerable to attacks that could have been prevented.
            </p>
            <p className='mb-2'>There are several risks that organizations face if they do not properly manage their attack surface when using cloud services:</p>
            <p className='mb-3'>  <span className='text-key'>•	Increased risk of cyber attacks:  </span> Without a proper inventory of the attack surface, an organization may be unaware of vulnerabilities and threats that exist within its cloud environment. This can significantly increase the risk of successful cyber attacks, as attackers may be able to exploit these vulnerabilities to gain access to sensitive data and systems.</p>
            <p className='mb-3'> <span className='text-key'> •	Compliance risks:  </span> Many organizations are required to meet certain cybersecurity standards, such as PCI DSS or HIPAA. Failing to properly manage the attack surface can put an organization at risk of non-compliance, which can lead to costly fines or penalties.</p>
            <p className='mb-3'> <span className='text-key'>•	Reputational damage:  </span> A successful cyber attack can significantly damage an organization's reputation, leading to lost customers and revenue. Failing to properly manage the attack surface can increase the risk of such an attack occurring.</p>
           
            <p className='mb-2'>To properly manage the attack surface when using cloud services, it is important to:</p>
            <p className='mb-4'>  <span className='text-key'>•	Inventory all internet-facing assets: </span> This includes both the organization's own assets, as well as those of the cloud service provider.</p>
            <p className='mb-4'>  <span className='text-key'>•	Assess vulnerabilities </span> Use automated tools and manual testing to identify vulnerabilities within the attack surface.</p>
            <p className='mb-4'>  <span className='text-key'>•	Assess threats:  </span> Analyze the likelihood of a vulnerability being exploited and the potential impact of a successful attack.</p>
            <p className='mb-4'>  <span className='text-key'>•	Implement security controls:   </span> Use appropriate security controls, such as firewalls, intrusion prevention systems, and web application firewalls, to protect against potential threats.</p>
            <p className='mb-4'>  <span className='text-key'>•	Monitor the environment:  </span> Continuously monitor the cloud environment for changes or unusual activity that may indicate a potential attack.</p>
            <p className='mb-2'>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Overall, the use of cloud services can bring many benefits to an organization, but it is important to properly manage the attack surface in order to reduce the risk of cyber attacks and protect sensitive data and assets. By following best practices and implementing appropriate security controls, organizations can effectively manage their attack surface and reduce the risk of successful cyber attacks.
            </p>
           
        </div>
    </div>
    <Footer />
</div>
  )
}

export default RiskCloudServices