
import IOCPage from "../src/assets/img/ioc-page.png"

function IOCIntelligence() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">IOC Intelligence Page</h2>
            <p>IOC Intelligence page contains various IOCs such as Malware Databases, Botnet IOCs, SSL Certificate Blacklists, URL Blacklists, Malicious Domains and Yara rules. That IOC information are daily updated and you can download them by clicking the related buttons.</p>
            <img className="mt-5 w-md-75" src={IOCPage} alt="IOC Intelligence  Page"  />
        </div>
    )
}

export default IOCIntelligence
