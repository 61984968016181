import ipAssetsFilters from "../src/assets/img/ip-assets-filters.png"
import ipAssetsPage from "../src/assets/img/ip-assets-page.png"

function IpAssets() {
    return (
        <div className="py-5 text-start px-lg-5 px-2 ms-lg-5 text-size" >
            <h2 className="mb-3 blue-heading">IP Assets Page</h2>
            <p>IP assets page contains your ip IP inventory with some additional information such as Services running on the IP.</p>
            <img className="w-md-75" src={ipAssetsPage} alt="Sidebar"  />
            <p className="my-3">You may use your search filters and/or search bar for limiting results to a specific data. Additionally, you can download your data in CSV format by clicking “Export to CSV” button.</p>
            <img src={ipAssetsFilters} alt="IP Assets Filter And Buttons"  />
        </div>
    )
}

export default IpAssets
