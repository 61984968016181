import React from 'react'
import AccordionComponent from './AccordionComponent'
import "./FAQ.css"
import faq from "../../assets/svg/faq.gif"

const FAQSection = () => {
  return (
    <div className='container'>
      <div className='row d-flex '>
        <h1 className='my-5' style={{color: "#0C007F"}}>FAQ</h1>
        <div className='col-lg-6 mt-5'>

        <AccordionComponent />
        </div>
        <div className='col-lg-6 d-flex align-items-start justify-content-center faq-image-wrapper'>
           <img src={faq} className="w-75 p"/>
        </div>

      </div>
    </div>
  )
}

export default FAQSection