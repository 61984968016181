import React from 'react'
import "animate.css/animate.min.css";
import './MainPage.css'
import Header from './Header/Header';
import FirstSection from './FirstSection/FirstSection';
import SecondSection from './SecondSection/SecondSection';
import ThirdSection from './ThirdSection/ThirdSection';
import Footer from './Footer/Footer';
import NewSection from './NewSection/NewSection';
import HowItWorks from './HowItWorks/HowItWorks';
import GetADemo from './GetADemo';
import ToggleSidebar from '../Sidebar/ToggleSidebar';

const MainPage = ({ handleClick , isActive}) => {


  return ( 
    <div className='main-page' >
        <Header handleClick={handleClick}/>
        <ToggleSidebar  isActive={isActive} />
        <FirstSection />
        <SecondSection />
        <NewSection/>
        <ThirdSection />
        <HowItWorks />
        <GetADemo />
        <Footer />
    </div>
  )
}

export default MainPage