import React from 'react'

const Row = ({title, paragraph, imgSrc, className}) => {
  return (
    <div className={`row d-flex  justify-content-center align-items-center text-start ${className}`}>
        <div className='col-lg-4 '>
            <h2 className='mb-5 mt-4'>{title} </h2>
            <p className='paragraph'>{paragraph}</p>
        </div>
        <div className='col-lg-6'>
            <img  src={imgSrc} className="w-75 image mt-5 ms-5 mb-5" />
        </div>
    </div>
  )
}

export default Row