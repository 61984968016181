import Accordion from 'react-bootstrap/Accordion';

function AccordionComponent() {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header> <h5>Which of my environments are covered?</h5></Accordion.Header>
        <Accordion.Body className='text-start'>
        Reposify covers your entire distributed IT ecosystem including:
          <ul>
              <li> On premise</li>
              <li>All cloud environments: SaaS, PaaS, IaaS</li>
              <li>Assets belonging to subsidiaries</li>
              <li>Assets managed by Third Party Vendors</li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header> <h5>Which types of assets does the platform detect?</h5> </Accordion.Header>
        <Accordion.Body className='text-start'>
        <ul>
              <li>Exposed sensitive services  </li>
              <li>Exposed domain assets</li>
              <li>Exposed S3 objects</li>
              <li>Exposed cloud assets</li>
              <li>Exposed credential leaks</li>
              <li>Assets belonging to subsidiaries</li>
              <li>CVE vulnerabilities</li>
              <li>HTTP header security</li>
          </ul>
          
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>  <h5>What is the installation and setup process?</h5></Accordion.Header>
        <Accordion.Body className='text-start'>
        Surfacemon’s platform is a SaaS solution that doesn’t require any installation or setup. The continuous discovery and analysis of assets is 100% autonomous and it requires minimum input such as domain name from your side.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>  <h5>How do you attribute assets to my company?</h5></Accordion.Header>
        <Accordion.Body className='text-start'>
        Surfacemon is designed to automatically scan and identify vulnerabilities in a company's external-facing systems and networks. These tools use a variety of techniques, such as port scanning, DNS records scanning and vulnerability scanning, to identify potential weaknesses that could be exploited by cyber criminals. By automating the process of identifying and prioritizing vulnerabilities, Surfacemon can help companies quickly and efficiently address potential security threats before they can be exploited.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionComponent;